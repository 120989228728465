import { TextField } from "@mui/material"
import { Fragment } from "react"
import { NumericFormat } from "react-number-format"
import formLocalizedHelperText from "utils/formLocalizedHelperText"

const FormCurrencyField = ({
	fieldName,
	formik,
	label,
	prefix = "",
	readOnly = false,
	disabled = false,
	decimalScale = 3
}) => {
	return (
		<Fragment>
			{formik && formik.values && formik.touched && formik.errors && (
				<NumericFormat
					id={fieldName}
					name={fieldName}
					label={label}
					value={formik.values[fieldName]}
					onChange={(event) => {
						let formattedValue =
							event.target.value &&
							parseFloat(event.target.value.split(" ").join(""))
						formik.setFieldValue(fieldName, formattedValue, true)
					}}
					error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
					helperText={
						formik.touched[fieldName] &&
						formLocalizedHelperText(formik.errors[fieldName])
					}
					color="formColor"
					variant="outlined"
					fullWidth
					customInput={TextField}
					InputProps={{ readOnly: readOnly, disabled: disabled }}
					allowNegative={false}
					thousandSeparator={" "}
					decimalScale={decimalScale}
					decimalSeparator="."
					prefix={prefix}
				/>
			)}
		</Fragment>
	)
}

export default FormCurrencyField
