import { Fab } from "@mui/material"
import React, { Fragment } from "react"

const ModalActionButton = ({
	icon = "",
	iconComponent = null,
	btnColor = "info",
	setOpen,
	setData = null,
	data,
	disabled
}) => {
	return (
		<Fragment>
			<Fab
				color={btnColor}
				variant="action"
				aria-label="modal-action"
				onClick={() => {
					if (!Boolean(disabled) && true) {
						setOpen(true)
						if (setData) {
							setData(data)
						}
					}
				}}
				disabled={disabled || false}
			>
				{iconComponent ? iconComponent : <i className={icon} />}
			</Fab>
		</Fragment>
	)
}

export default ModalActionButton
