import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import STAGE_TYPE_LIST from "shared/stageTypeList"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import * as yup from "yup"
import ProductionStageTableItem from "./items/ProductionStageTableItem"
import { useState } from "react"

const ProductionFromOneStageToAnotherModal = (props) => {
	const { open, setOpen, itemData, setItemData, setRefetch } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()
	const [staffsList, setStaffsList] = useState([])
	const [validationSchema, setValidationSchema] = useState(
		yup.object({
			name: yup
				.string()
				.required("production.fromOneStageToAnotherModal.validation.name"),
			size: yup
				.string()
				.required("production.fromOneStageToAnotherModal.validation.size"),
			number: yup
				.number()
				.min(0, {
					label: "production.fromOneStageToAnotherModal.validation.numberMin",
					value: 0
				})
				.max(
					itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
						? parseFloat(itemData?.prepare) || 0
						: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
						? parseFloat(itemData?.swegging) || 0
						: itemData.stageType === STAGE_TYPE_LIST.IRON.code
						? parseFloat(itemData?.iron) || 0
						: 0,
					{
						label: "production.fromOneStageToAnotherModal.validation.numberMax",
						value:
							itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
								? parseFloat(itemData?.prepare) || 0
								: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
								? parseFloat(itemData?.swegging) || 0
								: itemData.stageType === STAGE_TYPE_LIST.IRON.code
								? parseFloat(itemData?.iron) || 0
								: 0
					}
				)
				.required("production.fromOneStageToAnotherModal.validation.number"),
			stages: yup
				.array()
				.of(
					yup.object({
						staff_id: yup
							.string()
							.required(
								"production.fromOneStageToAnotherModal.validation.stageStaffId"
							),
						stage_id: yup
							.number()
							.required(
								"production.fromOneStageToAnotherModal.validation.stageId"
							),
						staffNumber: yup
							.string()
							.required(
								"production.fromOneStageToAnotherModal.validation.stageStaffNumber"
							),
						sum: yup
							.number()
							.min(0, {
								label:
									"production.fromOneStageToAnotherModal.validation.stageSumMin",
								value: 0
							})
							.required(
								"production.fromOneStageToAnotherModal.validation.stageSum"
							)
					})
				)
				.min(0, {
					label: "production.fromOneStageToAnotherModal.validation.stagesMin",
					value: 0
				})
				.required("production.fromOneStageToAnotherModal.validation.stages")
		})
	)

	const formik = useFormik({
		initialValues: {
			name: "",
			size: "",
			number: "",
			stages:
				itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
					? itemData?.product?.prepare &&
					  itemData?.product?.prepare.length > 0 &&
					  itemData?.product?.prepare.map((prepareItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: prepareItem?.id,
							sum: prepareItem?.sum
					  }))
					: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
					? itemData?.product?.sewingstage &&
					  itemData?.product?.sewingstage.length > 0 &&
					  itemData?.product?.sewingstage.map((sewingItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: sewingItem?.id,
							sum: sewingItem?.sum
					  }))
					: itemData.stageType === STAGE_TYPE_LIST.IRON.code
					? itemData?.product?.ironstages &&
					  itemData?.product?.ironstages.length > 0 &&
					  itemData?.product?.ironstages.map((ironItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: ironItem?.id,
							sum: ironItem?.sum
					  }))
					: []
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = JSON.parse(JSON.stringify(values))
			newValues.stages.forEach((el) => delete el.staffNumber)
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
					? `/staff/unit/prepare/${itemData.id}`
					: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
					? `/staff/unit/sewing/${itemData.id}`
					: itemData.stageType === STAGE_TYPE_LIST.IRON.code
					? `/staff/unit/iron/${itemData.id}`
					: "",
				newValues.name,
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		setItemData("")
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				{itemData.stageType === STAGE_TYPE_LIST.PREPARE.code ? (
					<span>{t(`production.fromOneStageToAnotherModal.prepareTitle`)}</span>
				) : itemData.stageType === STAGE_TYPE_LIST.SEAM.code ? (
					<span>{t(`production.fromOneStageToAnotherModal.seamTitle`)}</span>
				) : itemData.stageType === STAGE_TYPE_LIST.IRON.code ? (
					<span>{t(`production.fromOneStageToAnotherModal.ironTitle`)}</span>
				) : (
					""
				)}
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.lotName")}
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.lotSize")}
								fieldName="size"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.number")}
								fieldName="number"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<Box className="base-table flex flex-col mt-3">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 500, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.stageName")}</TableCell>
												<TableCell>{t("common.table.stageSum")}</TableCell>
												<TableCell>{t("common.table.tabelNumber")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
												? itemData?.product?.prepare &&
												  itemData?.product?.prepare.length > 0 &&
												  itemData?.product?.prepare.map(
														(prepareItem, index) => (
															<TableRow key={`stage-row-${index}`}>
																<ProductionStageTableItem
																	index={index}
																	stageData={prepareItem}
																	formik={formik}
																	staffsList={staffsList}
																	setStaffsList={setStaffsList}
																/>
															</TableRow>
														)
												  )
												: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
												? itemData?.product?.sewingstage &&
												  itemData?.product?.sewingstage.length > 0 &&
												  itemData?.product?.sewingstage.map(
														(sewingItem, index) => (
															<TableRow key={`stage-row-${index}`}>
																<ProductionStageTableItem
																	index={index}
																	stageData={sewingItem}
																	formik={formik}
																	staffsList={staffsList}
																	setStaffsList={setStaffsList}
																/>
															</TableRow>
														)
												  )
												: itemData.stageType === STAGE_TYPE_LIST.IRON.code
												? itemData?.product?.ironstages &&
												  itemData?.product?.ironstages.length > 0 &&
												  itemData?.product?.ironstages.map(
														(ironItem, index) => (
															<TableRow key={`stage-row-${index}`}>
																<ProductionStageTableItem
																	index={index}
																	stageData={ironItem}
																	formik={formik}
																	staffsList={staffsList}
																	setStaffsList={setStaffsList}
																/>
															</TableRow>
														)
												  )
												: []}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<FormHelperText
								children={
									<span>
										{formik.touched.stages &&
											formLocalizedHelperText(formik.errors.stages)}
									</span>
								}
								error={formik.touched.stages && Boolean(formik.errors.stages)}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ProductionFromOneStageToAnotherModal
