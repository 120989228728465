import { TextField } from "@mui/material"
import React, { Fragment } from "react"
import formLocalizedHelperText from "utils/formLocalizedHelperText"

const FormTextField = ({
	formik,
	fieldName,
	label,
	type = "text",
	onKeyDown = () => {},
	readOnly = false,
	disabled = false
}) => {
	return (
		<Fragment>
			{formik && formik.values && formik.touched && formik.errors && (
				<TextField
					color="formColor"
					variant="outlined"
					fullWidth
					id={fieldName}
					name={fieldName}
					label={label}
					type={type}
					error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
					helperText={
						formik.touched[fieldName] &&
						formLocalizedHelperText(formik.errors[fieldName])
					}
					value={formik.values[fieldName]}
					onChange={(event) => {
						formik.setFieldValue(fieldName, event.target.value, true)
					}}
					onKeyDown={onKeyDown}
					InputProps={{
						readOnly: readOnly,
						disabled: disabled
					}}
					autoComplete="off"
				/>
			)}
		</Fragment>
	)
}

export default FormTextField
