import {
	Box,
	Grid,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import { NumericFormat } from "react-number-format"
import FormTextField from "components/ui/form/FormTextField"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useQuery } from "react-query"
import SimpleAutocompleteRemoteField from "components/ui/simple-fields/select/SimpleAutocompleteRemoteField"
import FabricAddEditStageTableItem from "./items/FabricAddEditStageTableItem"

const validationSchema = yup.object({
	name: yup
		.string()
		.min(0, { label: "fabric.validation.nameMin", value: 0 })
		.required("fabric.validation.name"),
	amount: yup
		.number()
		.min(0, { label: "fabric.validation.amountMin", value: 0 })
		.required("fabric.validation.amount"),
	pusfine: yup
		.number()
		.min(0, { label: "fabric.validation.pusfineMin", value: 0 })
		.required("fabric.validation.pusfine"),
	gramaji: yup
		.number()
		.min(0, { label: "fabric.validation.gramajiMin", value: 0 })
		.required("fabric.validation.gramaji"),
	threads: yup
		.array()
		.of(
			yup.object({
				id: yup.number().required("fabric.validation.threadId"),
				amount: yup
					.number()
					.min(0, { label: "fabric.validation.threadAmountMin", value: 0 })
					.required("fabric.validation.threadAmount")
			})
		)
		.min(1, { label: "fabric.validation.threadsMin", value: 1 })
		.required("fabric.validation.threads"),
	works: yup
		.array()
		.of(
			yup.object({
				id: yup.number().required("fabric.validation.workId"),
				staff_id: yup.number().required("fabric.validation.workWorkerId"),
				staffNumber: yup.string().required("fabric.validation.workTabelNumber")
			})
		)
		.min(1, { label: "fabric.validation.worksMin", value: 1 })
		.required("fabric.validation.works")
})

const FabricAddEdit = () => {
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()
	const { setComponent } = useTopPanel()
	const [hasError, setHasError] = useState(false)
	const [selectedThreads, setSelectedThreads] = useState([])
	const [threadsList, setThreadsList] = useState([])
	const [staffsList, setStaffsList] = useState([])
	const [stagesList, setStagesList] = useState([])
	const axiosPrivate = useAxiosPrivate()

	const formik = useFormik({
		initialValues: {
			name: "",
			amount: "",
			pusfine: "",
			gramaji: "",
			threads: [
				{
					id: "",
					amount: ""
				}
			],
			works: [
				{
					id: "",
					staff_id: "",
					staffNumber: ""
				}
			]
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			// console.log("values = ", values)
			let newValues = JSON.parse(JSON.stringify(values))
			newValues.works.forEach((el) => delete el.staffNumber)
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				"/warehouse/material",
				newValues.name
			)
		}
	})

	const handleChangeThreads = (value) => {
		// console.log("value = ", value)
		value.forEach((item, index) => {
			formik.setFieldValue(
				`threads.${index}`,
				{ id: item, amount: formik.values.threads[index]?.amount || 0 },
				true
			)
		})
		let notIncludedItems = selectedThreads.filter(
			(item) => !value.includes(item)
		)
		if (notIncludedItems && notIncludedItems.length > 0) {
			formik.setFieldValue(
				"threads",
				formik.values.threads.filter((el) => !notIncludedItems.includes(el.id)),
				true
			)
		}
		setSelectedThreads(value)
	}

	const { isLoading, isFetching } = useQuery({
		queryKey: "productionStagesSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get("/data/production-stages")
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setStagesList([...data])
				data.forEach((item, index) => {
					formik.setFieldValue(
						`works.${index}`,
						{
							id: item.id,
							staff_id: formik.values.works[index]?.staff_id || "",
							staffNumber: formik.values.works[index]?.staffNumber || ""
						},
						true
					)
				})
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				<span>{t("fabric.addTitle")}</span>
			</div>
		)
	}, [])

	return (
		<div className="component-add-edit-wrapper mx-4">
			<div className="component-add-edit-body mt-3">
				<form onSubmit={formik.handleSubmit}>
					<Grid
						container
						spacing={{ xs: 2, sm: 2, lg: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={3} md={4} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.fabricName")}
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={3} md={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.fabricAmount")}
								fieldName="amount"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} lg={3} md={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.fabricPusfine")}
								fieldName="pusfine"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} lg={3} md={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.fabricGramaji")}
								fieldName="gramaji"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>
					</Grid>

					<Grid
						container
						spacing={{ xs: 2, sm: 2, lg: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<SimpleAutocompleteRemoteField
								label={t("common.fields.threads")}
								name="threads"
								itemValue="id"
								itemLabel="name"
								changeFn={handleChangeThreads}
								path="/data/threads"
								setData={setThreadsList}
							/>
						</Grid>

						<Grid item={true} lg={10} sm={12} xs={12}>
							<Box className="base-table flex flex-col">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 500, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.threadName")}</TableCell>
												<TableCell>{t("common.table.threadAmount")}</TableCell>
												<TableCell>{t("common.table.threadLeft")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{selectedThreads &&
												selectedThreads.length > 0 &&
												selectedThreads.map((item, index) => (
													<TableRow key={`thread-row-${index}`}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>
															<TextField
																color="formColor"
																variant="outlined"
																fullWidth
																id={`thread-name-${index}`}
																name={`thread-name-${index}`}
																label={t("common.fields.threadName")}
																value={
																	threadsList.find((el) => el.id === item)?.name
																}
																autoComplete="off"
																InputProps={{
																	disabled: true,
																	readOnly: true
																}}
															/>
														</TableCell>
														<TableCell>
															<NumericFormat
																id={`thread-amount-${index}`}
																name={`thread-amount-${index}`}
																label={t("common.fields.threadAmount")}
																value={formik.values.threads[index].amount}
																onChange={(event) => {
																	let formattedValue =
																		event.target.value &&
																		parseFloat(
																			event.target.value.split(" ").join("")
																		)
																	formik.setFieldValue(
																		`threads.${index}.amount`,
																		formattedValue,
																		true
																	)
																}}
																error={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.amount &&
																	Boolean(formik.errors.threads[index]?.amount)
																}
																helperText={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.amount &&
																	formik.errors.threads[index]?.amount
																		? formLocalizedHelperText(
																				formik.errors.threads[index].amount
																		  )
																		: ""
																}
																color="formColor"
																variant="outlined"
																fullWidth
																customInput={TextField}
																allowNegative={false}
																thousandSeparator={" "}
																decimalScale={3}
																decimalSeparator="."
															/>
														</TableCell>
														<TableCell>
															<NumericFormat
																value={
																	threadsList.find((el) => el.id === item)?.left
																}
																displayType={"text"}
																allowNegative={false}
																thousandSeparator={" "}
																decimalScale={1}
																decimalSeparator="."
																className="bg-transparent whitespace-nowrap"
															/>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>

						<Grid item={true} lg={10} sm={12} xs={12}>
							<Box className="base-table flex flex-col mt-3">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 500, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.stageName")}</TableCell>
												<TableCell>{t("common.table.tabelNumber")}</TableCell>
											</TableRow>
										</TableHead>
										{isLoading || isFetching ? (
											<TableBody className="overflow-hidden">
												<TableRow>
													<TableCell colSpan={3}>
														<LinearProgress />
													</TableCell>
												</TableRow>
											</TableBody>
										) : (
											<TableBody className="overflow-hidden">
												{stagesList &&
													stagesList.length > 0 &&
													stagesList.map((item, index) => (
														<TableRow key={`stage-row-${index}`}>
															<FabricAddEditStageTableItem
																index={index}
																itemName={item?.name}
																formik={formik}
																staffsList={staffsList}
																setStaffsList={setStaffsList}
															/>
														</TableRow>
													))}
											</TableBody>
										)}
									</Table>
								</TableContainer>
							</Box>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons isSubmitting={isSubmitting} />
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	)
}

export default FabricAddEdit
