import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormAutocompleteCustomNestedItemField from "components/ui/form/FormAutocompleteCustomNestedItemField"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
	product_id: yup
		.number()
		.required("production.addEditModal.validation.productId"),
	material_id: yup
		.number()
		.required("production.addEditModal.validation.fabricId"),
	amount: yup
		.number()
		.min(0, {
			label: "production.addEditModal.validation.fabricAmountMin",
			value: 0
		})
		.required("production.addEditModal.validation.fabricAmount"),
	count: yup
		.number()
		.min(0, { label: "production.addEditModal.validation.countMin", value: 0 })
		.required("production.addEditModal.validation.count")
})

const ProductionAddEditModal = (props) => {
	const { open, setOpen, setRefetch } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			product_id: "",
			material_id: "",
			amount: "",
			count: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				values,
				"/staff/prepare",
				values.name,
				null,
				false,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>{t("production.addEditModal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<FormAutocompleteField
								label={t("common.fields.productName")}
								fieldName="product_id"
								formik={formik}
								path="/data/products"
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormAutocompleteCustomNestedItemField
								label={t("common.fields.fabricName")}
								fieldName="material_id"
								formik={formik}
								path="/data/materials"
								itemName="material.name"
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.amount")}
								fieldName="amount"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.count")}
								fieldName="count"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ProductionAddEditModal
