import { Button } from "@mui/material"
import SimpleRemoteSelectChangeFnField from "components/ui/simple-fields/select/SimpleRemoteSelectChangeFnField"
import BaseTable from "components/ui/tables/BaseTable"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SubProcessAddEditModal from "./SubProcessAddEditModal"

const Process = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [process, setProcess] = useState(1)
	const [openSubProcessModal, setOpenSubProcessModal] = useState(false)
	const [itemId, setItemId] = useState("")

	useEffect(() => {
		if (process) {
			setRefetch(true)
		}
	}, [process])

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("process.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full">
						<div className="w-[400px]">
							<SimpleRemoteSelectChangeFnField
								label={t("process.processesSelect")}
								name="processes"
								path="/admin/stage/index"
								value={process}
								changeFn={setProcess}
							/>
						</div>
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="success"
							className="!mx-2"
							onClick={() => {
								setOpenSubProcessModal(true)
							}}
						>
							<i className="bi bi-plus-circle" />
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>

			<div className="component-table-wrapper">
				<BaseTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath={`admin/stage/substages/${process}`}
					pathChangeable={true}
					emitTableColumns={{ open, setOpen }}
					tableName="processes"
					headCells={[
						{ code: "id", label: "#" },
						{ code: "name", label: t("common.table.name") },
						{ code: "sum", label: t("common.table.stageSum") }
					]}
					columns={[
						{ code: "id" },
						{ code: "name" },
						{ code: "sum", type: "numberFormat" }
					]}
					actionModalEdit={{
						setOpen: setOpenSubProcessModal,
						setItemId: setItemId
					}}
				/>
			</div>

			{openSubProcessModal && (
				<SubProcessAddEditModal
					open={openSubProcessModal}
					setOpen={setOpenSubProcessModal}
					itemId={itemId}
					processId={process}
					setItemId={setItemId}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	)
}

export default Process
