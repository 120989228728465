import useLocalStorage from "hooks/useLocalStorage"
import useNotification from "hooks/useNotification"
import { useEffect, useMemo } from "react"
import {
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams
} from "react-router-dom"
import { AuthContext } from "../index"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import { useTranslation } from "react-i18next"

const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("user", null)
	const { t } = useTranslation()
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const sendNotification = useNotification()

	const login = async (data) => {
		sendNotification({
			msg: t("common.alerts.success.login"),
			variant: "success"
		})
		setUser(data)
	}

	useEffect(() => {
		if (user && user.user) {
			if (user && user?.user?.role) {
				if (searchParams.has("fromPath")) {
					navigate(searchParams.get("fromPath"), { replace: true })
				} else {
					if (user?.user?.role === ROLE_TYPE_LIST.ADMIN.code) {
						if (location.pathname.includes("admin")) {
							navigate(location.pathname + location?.search, { replace: true })
						} else {
							navigate("/admin/dashboard", { replace: true })
						}
					} else {
						navigate("/admin/dashboard", { replace: true })
					}
				}
			} else {
				navigate("/admin/dashboard", { replace: true })
			}
		} else {
			navigate(
				`/login${
					location.pathname && location.pathname !== "/login"
						? `?fromPath=${location.pathname}`
						: ""
				}`,
				{ replace: true }
			)
		}
	}, [user])

	const logout = async () => {
		setUser(null)
		navigate("/login", { replace: true })
	}

	const value = useMemo(
		() => ({
			user,
			login,
			logout
		}),
		[user]
	)

	return (
		<AuthContext.Provider value={[value, setUser]}>
			<Outlet />
		</AuthContext.Provider>
	)
}

export default AuthProvider
