import {
	Box,
	Button,
	Fab,
	FormHelperText,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormSelectField from "components/ui/form/FormSelectField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect } from "react"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import { NumericFormat } from "react-number-format"

const validationSchema = yup.object({
	company_id: yup.number().required("thread.validation.company"),
	rasxod: yup
		.number()
		.min(0, { label: "thread.validation.expenseMin", value: 0 })
		.required("thread.validation.expense"),
	products: yup
		.array()
		.of(
			yup.object({
				name: yup.string().required("thread.validation.productName"),
				amount: yup.number().required("thread.validation.productAmount"),
				price: yup.number().required("thread.validation.productPrice"),
				party: yup.string().required("thread.validation.productParty"),
				brutto: yup.string().required("thread.validation.productBrutto")
			})
		)
		.min(1, { label: "thread.validation.productsMin", value: 1 })
		.required("thread.validation.products")
})

const ThreadAddEdit = () => {
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()
	const { setComponent } = useTopPanel()

	const formik = useFormik({
		initialValues: {
			company_id: "",
			rasxod: "",
			products: [
				{
					name: "",
					amount: "",
					price: "",
					party: "",
					brutto: ""
				}
			]
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				values,
				"/admin/thread",
				values.name
			)
		}
	})

	const handleAddNewRow = () => {
		formik.setFieldValue(
			"products",
			[
				...formik.values.products,
				{
					name: "",
					amount: "",
					price: "",
					party: "",
					brutto: ""
				}
			],
			true
		)
	}

	const handleDeleteRow = (rowIndex) => {
		formik.values.products.splice(rowIndex, 1)
		let newValues = JSON.parse(JSON.stringify(formik.values.products))
		formik.setFieldValue("products", newValues, true)
	}

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				<span>{t("thread.addTitle")}</span>
			</div>
		)
	}, [])

	return (
		<div className="component-add-edit-wrapper mx-4">
			<div className="component-add-edit-body mt-3">
				<form onSubmit={formik.handleSubmit}>
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormSelectField
								label={t("common.fields.company")}
								fieldName="company_id"
								formik={formik}
								path={"/data/companies"}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.expense")}
								fieldName="rasxod"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<div className="text-end mb-4">
								<Button
									variant="outlined"
									color="success"
									onClick={() => handleAddNewRow()}
								>
									<i className="bi bi-plus-circle text-base mr-1" />
									{t("common.button.add")}
								</Button>
							</div>
							<Box className="base-table flex flex-col">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 750, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.productName")}</TableCell>
												<TableCell>{t("common.table.productAmount")}</TableCell>
												<TableCell>{t("common.table.productPrice")}</TableCell>
												<TableCell>{t("common.table.productParty")}</TableCell>
												<TableCell>{t("common.table.productBrutto")}</TableCell>
												<TableCell>{t("common.table.actions")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{formik.values.products.map((item, index) => (
												<TableRow key={`product-row-${index}`}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<TextField
															color="formColor"
															variant="outlined"
															fullWidth
															id={`product-name-${index}`}
															name={`product-name-${index}`}
															label={t("common.fields.productName")}
															error={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.name &&
																Boolean(formik.errors.products[index]?.name)
															}
															helperText={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.name &&
																formik.errors.products[index]?.name
																	? formLocalizedHelperText(
																			formik.errors.products[index].name
																	  )
																	: ""
															}
															value={formik.values.products[index].name}
															onChange={(event) => {
																formik.setFieldValue(
																	`products.${index}.name`,
																	event.target.value,
																	true
																)
															}}
															autoComplete="off"
														/>
													</TableCell>
													<TableCell>
														<NumericFormat
															id={`product-amount-${index}`}
															name={`product-amount-${index}`}
															label={t("common.fields.productAmount")}
															value={formik.values.products[index].amount}
															onChange={(event) => {
																let formattedValue =
																	event.target.value &&
																	parseFloat(
																		event.target.value.split(" ").join("")
																	)
																formik.setFieldValue(
																	`products.${index}.amount`,
																	formattedValue,
																	true
																)
															}}
															error={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.amount &&
																Boolean(formik.errors.products[index]?.amount)
															}
															helperText={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.amount &&
																formik.errors.products[index]?.amount
																	? formLocalizedHelperText(
																			formik.errors.products[index].amount
																	  )
																	: ""
															}
															color="formColor"
															variant="outlined"
															fullWidth
															customInput={TextField}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={3}
															decimalSeparator="."
														/>
													</TableCell>
													<TableCell>
														<NumericFormat
															id={`product-price-${index}`}
															name={`product-price-${index}`}
															label={t("common.fields.productPrice")}
															value={formik.values.products[index].price}
															onChange={(event) => {
																let formattedValue =
																	event.target.value &&
																	parseFloat(
																		event.target.value.split(" ").join("")
																	)
																formik.setFieldValue(
																	`products.${index}.price`,
																	formattedValue,
																	true
																)
															}}
															error={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.price &&
																Boolean(formik.errors.products[index]?.price)
															}
															helperText={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.price &&
																formik.errors.products[index]?.price
																	? formLocalizedHelperText(
																			formik.errors.products[index].price
																	  )
																	: ""
															}
															color="formColor"
															variant="outlined"
															fullWidth
															customInput={TextField}
															allowNegative={false}
															decimalScale={3}
															decimalSeparator="."
														/>
													</TableCell>
													<TableCell>
														<TextField
															color="formColor"
															variant="outlined"
															fullWidth
															id={`product-party-${index}`}
															name={`product-party-${index}`}
															label={t("common.fields.productParty")}
															error={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.party &&
																Boolean(formik.errors.products[index]?.party)
															}
															helperText={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.party &&
																formik.errors.products[index]?.party
																	? formLocalizedHelperText(
																			formik.errors.products[index].party
																	  )
																	: ""
															}
															value={formik.values.products[index].party}
															onChange={(event) => {
																formik.setFieldValue(
																	`products.${index}.party`,
																	event.target.value,
																	true
																)
															}}
															autoComplete="off"
														/>
													</TableCell>
													<TableCell>
														<NumericFormat
															id={`product-brutto-${index}`}
															name={`product-brutto-${index}`}
															label={t("common.fields.productBrutto")}
															value={formik.values.products[index].brutto}
															onChange={(event) => {
																let formattedValue =
																	event.target.value &&
																	parseFloat(
																		event.target.value.split(" ").join("")
																	)
																formik.setFieldValue(
																	`products.${index}.brutto`,
																	formattedValue,
																	true
																)
															}}
															error={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.brutto &&
																Boolean(formik.errors.products[index]?.brutto)
															}
															helperText={
																formik.touched.products &&
																formik.errors.products &&
																formik.touched.products.length > 0 &&
																formik.errors.products.length > 0 &&
																formik.touched.products[index]?.brutto &&
																formik.errors.products[index]?.brutto
																	? formLocalizedHelperText(
																			formik.errors.products[index].brutto
																	  )
																	: ""
															}
															color="formColor"
															variant="outlined"
															fullWidth
															customInput={TextField}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={3}
															decimalSeparator="."
														/>
													</TableCell>
													<TableCell>
														<Fab
															color="error"
															variant="action"
															aria-label="delete"
															onClick={() => handleDeleteRow(index)}
														>
															<i className="bi bi-trash3" />
														</Fab>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<FormHelperText
								children={
									<span>
										{formik.touched.products &&
											formLocalizedHelperText(formik.errors.products)}
									</span>
								}
								error={
									formik.touched.products && Boolean(formik.errors.products)
								}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons isSubmitting={isSubmitting} />
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	)
}

export default ThreadAddEdit
