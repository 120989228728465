import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

const SimpleAutocompleteRemoteField = ({
	path,
	label,
	name,
	itemValue = "id",
	itemName = "name",
	changeFn = () => {},
	readOnly = false,
	disabled = false,
	pathChangeable = false,
	setData = () => {}
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: name + "AutocompleteSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get(path)
			if (setData && typeof setData === "function") {
				setData(response.data.data)
			}
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		if (pathChangeable) {
			refetch()
			changeFn("")
		}
	}, [path])

	return (
		<Fragment>
			<Autocomplete
				id={`${name}-autocomplete`}
				options={data || []}
				disableClearable
				fullWidth
				multiple
				loading={isLoading || isFetching}
				noOptionsText={t("common.global.noDataFound")}
				getOptionLabel={(option) =>
					option[itemName] ? `${option[itemName]}` : ""
				}
				onChange={(event, val) => {
					changeFn(val.length > 0 ? val.map((el) => el[itemValue]) : [])
				}}
				readOnly={readOnly}
				disabled={disabled}
				loadingText={
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				}
				renderTags={(tagValue, getTagProps) => {
					return tagValue.map((option, index) => (
						<Chip
							variant="tableBadge"
							color="primary"
							{...getTagProps({ index })}
							label={option[itemName]}
						/>
					))
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						color="formColor"
						variant="outlined"
						label={label}
						autoComplete="off"
					/>
				)}
			/>
		</Fragment>
	)
}

export default SimpleAutocompleteRemoteField
