import { Button } from "@mui/material"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import BaseTable from "components/ui/tables/BaseTable"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import SendThreadToWasteModal from "./SendThreadToWasteModal"

const Thread = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [openSendThreadToWasteModal, setOpenSendThreadToWasteModal] =
		useState(false)

	const [itemData, setItemData] = useState("")

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("thread.title")}
			</div>
		)
	}, [i18n.language])

	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14"></div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="success"
							className="!mx-2"
							onClick={() => {
								navigate("/admin/thread/add")
							}}
						>
							<i className="bi bi-plus-circle" />
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>

			<div className="component-table-wrapper">
				<BaseTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="admin/thread/index"
					emitTableColumns={{ open, setOpen }}
					tableName="threads"
					headCells={[
						{ code: "id", label: "#" },
						{ code: "name", label: t("common.table.threadName") },
						{ code: "amount", label: t("common.table.amount") },
						{ code: "left", label: t("common.table.leftAmount") },
						{ code: "brutto", label: t("common.table.productBrutto") },
						{ code: "cost", label: t("common.table.cost") },
						{ code: "price", label: t("common.table.price") },
						{ code: "rasxod", label: t("common.table.expense") },
						{ code: "party", label: t("common.table.party") },
						{ code: "date", label: t("common.table.date") },
						{ code: "staff", label: t("common.table.staff") }
					]}
					columns={[
						{ code: "id" },
						{ code: "name" },
						{
							code: "amount",
							type: "numberFormat",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{
							code: "left",
							type: "numberFormat",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{
							code: "brutto",
							type: "numberFormat",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{ code: "cost", type: "numberFormat", suffix: " $" },
						{ code: "price", type: "numberFormat", suffix: " $" },
						{
							code: "thread",
							type: "nestedNumberFormat",
							decimalScale: 0,
							childStr: "rasxod"
						},
						{ code: "party" },
						{ code: "date", type: "date" },
						{
							code: "thread",
							type: "nested",
							childStr: "staff.name"
						}
					]}
					actionModal={{
						icon: "bi bi-trash",
						btnColor: "error",
						setOpen: setOpenSendThreadToWasteModal,
						setData: setItemData
					}}
				/>
			</div>
			{openSendThreadToWasteModal && (
				<SendThreadToWasteModal
					open={openSendThreadToWasteModal}
					setOpen={setOpenSendThreadToWasteModal}
					itemData={itemData}
					setItemData={setItemData}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	)
}

export default Thread
