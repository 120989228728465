import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
	staff_id: yup.number().required("salary.modal.validation.staffId"),
	payment: yup
		.number()
		.min(0, {
			label: "salary.modal.validation.paymentMin",
			value: 0
		})
		.required("salary.modal.validation.payment")
})

const SalaryAddModal = (props) => {
	const { open, setOpen, setRefetch } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			staff_id: "",
			payment: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "formData" },
				values,
				"/admin/salary",
				{
					title: t("salary.modal.notification.success")
				},
				null,
				false,
				handleFinishRequest
			)
		}
	})

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>{t("salary.modal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} sm={12} xs={12}>
							<FormAutocompleteField
								label={t("common.fields.simpleStaff")}
								fieldName="staff_id"
								formik={formik}
								path="/data/staffes"
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormCurrencyField
								label={t("common.fields.salary")}
								fieldName="payment"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default SalaryAddModal
