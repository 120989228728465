import CLIENT_TYPE from "./clientTypeList"
import CURRENCY_TYPE from "./currencyTypeList"
import STAGE_TYPE_LIST from "./stageTypeList"

const clientTypeSelectOptions = [
	{
		code: CLIENT_TYPE.PHYSICAL.code,
		label: CLIENT_TYPE.PHYSICAL.label
	},
	{
		code: CLIENT_TYPE.LEGAL.code,
		label: CLIENT_TYPE.LEGAL.label
	}
]

const currencyTypeSelectOptions = [
	{
		code: CURRENCY_TYPE.SUM.code,
		label: CURRENCY_TYPE.SUM.label
	},
	{
		code: CURRENCY_TYPE.VALUTE.code,
		label: CURRENCY_TYPE.VALUTE.label
	}
]

const stageTypeSelectOptions = [
	{
		code: STAGE_TYPE_LIST.PREPARE.code,
		label: STAGE_TYPE_LIST.PREPARE.label
	},
	{
		code: STAGE_TYPE_LIST.SEAM.code,
		label: STAGE_TYPE_LIST.SEAM.label
	},
	{
		code: STAGE_TYPE_LIST.IRON.code,
		label: STAGE_TYPE_LIST.IRON.label
	}
]

export {
	clientTypeSelectOptions,
	currencyTypeSelectOptions,
	stageTypeSelectOptions
}
