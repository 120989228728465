import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import React from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
	name: yup.string().required("products.accessoriesModal.validation.name"),
	number: yup
		.number()
		.min(0, {
			label: "products.accessoriesModal.validation.numberMin",
			value: 0
		})
		.required("products.accessoriesModal.validation.number")
})

const ProductAccessoriesModal = (props) => {
	const { open, setOpenEdit, setRefetch, productId, itemData, setItemData } =
		props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			name: itemData?.name || "",
			number: itemData?.number || ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if (itemData?.id) {
				console.log("as")
			} else {
				submit(
					{ type: "post", contentType: "simple" },
					values,
					`/admin/product/addaccess/${productId}`,
					values.name,
					null,
					true,
					handleFinishRequest
				)
			}
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		setItemData("")
		handleClose()
	}

	const handleClose = () => {
		setOpenEdit(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				{itemData?.id ? (
					<span>
						{t("products.accessoriesModal.editTitle", {
							name: itemData?.name
						})}
					</span>
				) : (
					<span>{t("products.accessoriesModal.addTitle")}</span>
				)}

				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.accessoryName")}
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.accessoryNumber")}
								fieldName="number"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpenEdit={setOpenEdit}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ProductAccessoriesModal
