import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	Outlet
} from "react-router-dom"

import NotFoundPage from "components/ui/NotFoundPage"

import BaseLayout from "layouts/BaseLayout"

import LogInSignUpLayout from "layouts/LogInSignUpLayout"
import LogIn from "pages/login-signup/LogIn"
import Dashboard from "pages/admin/dashboard/Dashboard"
import Settings from "pages/admin/settings/Settings"
import Companies from "pages/admin/settings/company/Companies"
import CompanyAddEdit from "pages/admin/settings/company/CompanyAddEdit"
import Staff from "pages/admin/settings/staff/Staff"
import AuthProvider from "context/providers/AuthProvider"
import Profile from "pages/profile/Profile"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import RequireAuth from "hoc/RequireAuth"
import Currency from "pages/admin/currency/Currency"
import BaseProvider from "context/BaseProvider"
import Thread from "pages/admin/thread/Thread"
import ThreadAddEdit from "pages/admin/thread/ThreadAddEdit"
import Process from "pages/admin/process/Process"
import Fabric from "pages/admin/fabric/Fabric"
import FabricAddEdit from "pages/admin/fabric/FabricAddEdit"
import PaintSent from "pages/admin/paint/PaintSent"
import PaintCame from "pages/admin/paint/PaintCame"
import Products from "pages/admin/products/Products"
import ProductView from "pages/admin/products/ProductView"
import Production from "pages/admin/production/Production"
import GetFromService from "pages/admin/get-from-service/GetFromService"
import Sale from "pages/admin/sale/Sale"
import SaleAddEdit from "pages/admin/sale/SaleAddEdit"
import Salary from "pages/admin/salary/Salary"
import SendToService from "pages/admin/sent-to-service/SendToService"
import Done from "pages/admin/done/Done"

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Outlet />}>
			<Route element={<AuthProvider />}>
				<Route element={<LogInSignUpLayout />}>
					<Route path="login" element={<LogIn />} />
				</Route>
				<Route element={<BaseProvider />}>
					<Route element={<BaseLayout />}>
						<Route
							element={
								<RequireAuth allowedRoles={[ROLE_TYPE_LIST.ADMIN.code]} />
							}
							path="admin"
						>
							<Route path="dashboard" element={<Dashboard />} />

							<Route path="thread" element={<Outlet />}>
								<Route index element={<Thread />} />
								<Route path="add" element={<ThreadAddEdit />} />
							</Route>

							<Route path="process" element={<Process />} />

							<Route path="production" element={<Production />} />
							<Route path="send-to-service" element={<SendToService />} />
							<Route path="get-from-service" element={<GetFromService />} />
							<Route path="done" element={<Done />} />
							<Route path="fabric" element={<Outlet />}>
								<Route index element={<Fabric />} />
								<Route path="add" element={<FabricAddEdit />} />
							</Route>
							<Route path="sale" element={<Outlet />}>
								<Route index element={<Sale />} />
								<Route path="add" element={<SaleAddEdit />} />
							</Route>
							<Route path="paint-sent" element={<PaintSent />} />
							<Route path="paint-came" element={<PaintCame />} />

							<Route path="products" element={<Outlet />}>
								<Route index element={<Products />} />
								<Route path="view/:id" element={<ProductView />} />
							</Route>
							<Route path="salary" element={<Salary />} />
							{/* <Route path="payments" element={<Outlet />}>
								<Route index element={<Payments />} />
								<Route path="view/:id" element={<ProductView />} />
							</Route> */}

							<Route path="currency" element={<Currency />} />

							<Route path="settings" element={<Outlet />}>
								<Route index element={<Settings />} />
								<Route path="company" element={<Outlet />}>
									<Route index element={<Companies />} />
									<Route path="add" element={<CompanyAddEdit />} />
									<Route path="edit/:id" element={<CompanyAddEdit />} />
								</Route>
								<Route path="staff" element={<Staff />} />
							</Route>
						</Route>

						<Route path="profile" element={<Profile />} />

						<Route path="not-found" element={<NotFoundPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Route>
			</Route>
		</Route>
	)
)

export default router
