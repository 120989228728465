import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormSelectField from "components/ui/form/FormSelectField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const SendFabricToPaintModal = (props) => {
	const { open, setOpen, setRefetch, itemData, setItemData } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const validationSchema = yup.object({
		company_id: yup
			.string()
			.required("fabric.sendToPaintModal.validation.company"),
		rulon: yup
			.number()
			.min(0, {
				label: "fabric.sendToPaintModal.validation.rulonMin",
				value: 0
			})
			.required("fabric.sendToPaintModal.validation.rulon"),
		hom: yup
			.number()
			.min(0, { label: "fabric.sendToPaintModal.validation.homMin", value: 0 })
			.max(parseFloat(itemData?.left), {
				label: "fabric.sendToPaintModal.validation.homMax",
				value: parseFloat(itemData?.left)
			})
			.required("fabric.sendToPaintModal.validation.hom"),
		color: yup.string().required("fabric.sendToPaintModal.validation.color")
	})

	const formik = useFormik({
		initialValues: {
			company_id: "",
			rulon: "",
			hom: "",
			color: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				{ material_id: itemData?.id, ...values },
				"/warehouse/colour/send",
				{
					title: t("fabric.sendToPaintModal.notification.sent", {
						name: itemData?.name
					})
				},
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setItemData("")
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>
					{t("fabric.sendToPaintModal.title", { name: itemData?.name })}
				</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				{parseFloat(itemData?.left) <= 0 ? (
					<div className="py-2">
						<Alert severity="warning">
							{t("fabric.sendToPaintModal.alerts.couldNotSend")}
						</Alert>
					</div>
				) : (
					<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
						<Grid
							container
							spacing={{ xs: 2, sm: 3, lg: 3 }}
							rowSpacing={1}
							columns={{ xs: 12, sm: 12, lg: 12 }}
						>
							<Grid item={true} sm={6} xs={12}>
								<FormSelectField
									label={t("common.fields.paintCompany")}
									fieldName="company_id"
									formik={formik}
									path={"/data/color-companies"}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormCurrencyField
									label={t("common.fields.rulon")}
									fieldName="rulon"
									formik={formik}
									decimalScale={0}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormCurrencyField
									label={t("common.fields.hom")}
									fieldName="hom"
									formik={formik}
									decimalScale={0}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormTextField
									label={t("common.fields.color")}
									fieldName="color"
									formik={formik}
								/>
							</Grid>

							<Grid item={true} sm={12} xs={12}>
								<FormActionButtons
									isSubmitting={isSubmitting}
									formType="dialog"
									setOpen={setOpen}
									reset={formik.resetForm}
								/>
							</Grid>
						</Grid>
					</form>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default SendFabricToPaintModal
