import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Fab,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormFileUploadField from "components/ui/form/FormFileUploadField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useFormSubmit from "hooks/useFormSubmit"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import * as yup from "yup"

const validationSchema = yup.object({
	name: yup.string().required("products.addEditModal.validation.name"),
	model: yup.string().required("products.addEditModal.validation.model"),
	price: yup
		.number()
		.min(0, { label: "products.addEditModal.validation.priceMin", value: 0 })
		.required("products.addEditModal.validation.price"),
	image: yup.string().required("products.addEditModal.validation.image"),
	stages: yup
		.array()
		.of(
			yup.object({
				name: yup
					.string()
					.required("products.addEditModal.validation.stageName"),
				sum: yup
					.number()
					.min(0, {
						label: "products.addEditModal.validation.stageSumMin",
						value: 0
					})
					.required("products.addEditModal.validation.stageSum"),
				type: yup
					.number()
					.required("products.addEditModal.validation.stageType")
			})
		)
		.min(1, { label: "products.addEditModal.validation.stagesMin", value: 1 })
		.required("products.addEditModal.validation.stages"),
	accessories: yup
		.array()
		.of(
			yup.object({
				name: yup
					.string()
					.required("products.addEditModal.validation.accessoryName"),
				number: yup
					.number()
					.min(0, {
						label: "products.addEditModal.validation.accessoryNumberMin",
						value: 0
					})
					.required("products.addEditModal.validation.accessoryNumber")
			})
		)
		.min(1, {
			label: "products.addEditModal.validation.accessoriesMin",
			value: 1
		})
		.required("products.addEditModal.validation.accessories")
})

const ProductAddEditModal = (props) => {
	const { open, setOpen, setRefetch } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)

	const formik = useFormik({
		initialValues: {
			name: "",
			model: "",
			price: "",
			image: null,
			stages: [
				{
					name: "",
					sum: "",
					type: ""
				}
			],
			accessories: [
				{
					name: "",
					number: ""
				}
			]
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "formData" },
				values,
				"/admin/product",
				values.name,
				null,
				false,
				handleFinishRequest
			)
		}
	})

	const handleAddNewRow = () => {
		formik.setFieldValue(
			"stages",
			[
				...formik.values.stages,
				{
					name: "",
					sum: "",
					type: ""
				}
			],
			true
		)
	}

	const handleAddNewAccessoriesRow = () => {
		formik.setFieldValue(
			"accessories",
			[
				...formik.values.accessories,
				{
					name: "",
					number: ""
				}
			],
			true
		)
	}
	const handleDeleteRow = (rowIndex) => {
		formik.values.stages.splice(rowIndex, 1)
		let newValues = JSON.parse(JSON.stringify(formik.values.stages))
		formik.setFieldValue("stages", newValues, true)
	}

	const handleDeleteRowAccessories = (rowIndex) => {
		formik.values.accessories.splice(rowIndex, 1)
		let newValues = JSON.parse(JSON.stringify(formik.values.accessories))
		formik.setFieldValue("accessories", newValues, true)
	}

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	const {
		data: stageTypesData,
		isLoading,
		isFetching
	} = useQuery({
		queryKey: "stageTypesSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get("/data/stage-types")
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>{t("products.addEditModal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.productName")}
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.productModel")}
								fieldName="model"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.productPrice")}
								fieldName="price"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormFileUploadField
								accept=".jpg, .png"
								fieldName="image"
								formik={formik}
								label={t("common.fields.image")}
								btnLabel={t("common.button.imageUpload")}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<div className="text-end mb-2">
								<Button
									variant="outlined"
									color="success"
									onClick={() => handleAddNewRow()}
								>
									<i className="bi bi-plus-circle text-base mr-1" />
									{t("common.button.add")}
								</Button>
							</div>
							<Box className="base-table flex flex-col">
								<TableContainer className="flex-auto h-full">
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.stageName")}</TableCell>
												<TableCell>{t("common.table.stageSum")}</TableCell>
												<TableCell>{t("common.table.stageType")}</TableCell>
												<TableCell>{t("common.table.actions")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{formik.values.stages.map((item, index) => (
												<TableRow key={`stage-row-${index}`}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<TextField
															color="formColor"
															variant="outlined"
															fullWidth
															id={`stage-name-${index}`}
															name={`stage-name-${index}`}
															label={t("common.fields.stageName")}
															error={
																formik.touched.stages &&
																formik.errors.stages &&
																formik.touched.stages.length > 0 &&
																formik.errors.stages.length > 0 &&
																formik.touched.stages[index]?.name &&
																Boolean(formik.errors.stages[index]?.name)
															}
															helperText={
																formik.touched.stages &&
																formik.errors.stages &&
																formik.touched.stages.length > 0 &&
																formik.errors.stages.length > 0 &&
																formik.touched.stages[index]?.name &&
																formik.errors.stages[index]?.name
																	? formLocalizedHelperText(
																			formik.errors.stages[index].name
																	  )
																	: ""
															}
															value={formik.values.stages[index].name}
															onChange={(event) => {
																formik.setFieldValue(
																	`stages.${index}.name`,
																	event.target.value,
																	true
																)
															}}
															autoComplete="off"
														/>
													</TableCell>
													<TableCell>
														<NumericFormat
															id={`stage-sum-${index}`}
															name={`stage-sum-${index}`}
															label={t("common.fields.stageSum")}
															value={formik.values.stages[index].sum}
															onChange={(event) => {
																let formattedValue =
																	event.target.value &&
																	parseFloat(
																		event.target.value.split(" ").join("")
																	)
																formik.setFieldValue(
																	`stages.${index}.sum`,
																	formattedValue,
																	true
																)
															}}
															error={
																formik.touched.stages &&
																formik.errors.stages &&
																formik.touched.stages.length > 0 &&
																formik.errors.stages.length > 0 &&
																formik.touched.stages[index]?.sum &&
																Boolean(formik.errors.stages[index]?.sum)
															}
															helperText={
																formik.touched.stages &&
																formik.errors.stages &&
																formik.touched.stages.length > 0 &&
																formik.errors.stages.length > 0 &&
																formik.touched.stages[index]?.sum &&
																formik.errors.stages[index]?.sum
																	? formLocalizedHelperText(
																			formik.errors.stages[index].sum
																	  )
																	: ""
															}
															color="formColor"
															variant="outlined"
															fullWidth
															customInput={TextField}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={3}
															decimalSeparator="."
														/>
													</TableCell>
													<TableCell>
														<FormControl
															fullWidth
															color="formColor"
															error={
																formik.touched.stages &&
																formik.errors.stages &&
																formik.touched.stages.length > 0 &&
																formik.errors.stages.length > 0 &&
																formik.touched.stages[index]?.type &&
																Boolean(formik.errors.stages[index]?.type)
															}
														>
															<InputLabel id={`stage-type-${index}-label`}>
																{t("common.fields.stageType")}
															</InputLabel>
															<Select
																labelId={`stage-type-${index}-label`}
																id={`stage-type-${index}-select`}
																label={t("common.fields.stageType")}
																onChange={(event) => {
																	formik.setFieldValue(
																		`stages.${index}.type`,
																		event.target.value,
																		true
																	)
																}}
																value={
																	isLoading || isFetching
																		? ""
																		: formik.values.stages[index].type
																}
																color="formColor"
																variant="outlined"
																role="presentation"
																MenuProps={{
																	id: `stage-type-${index}-select-menu`,
																	disableScrollLock: true,
																	PaperProps: {
																		style: {
																			maxHeight: 300
																		}
																	}
																}}
															>
																{isLoading || isFetching ? (
																	<div className="circular-progress-box">
																		<CircularProgress size={25} />
																	</div>
																) : stageTypesData &&
																  stageTypesData.length > 0 ? (
																	stageTypesData.map((item, index) => (
																		<MenuItem value={item.id} key={index + 1}>
																			{item.name}
																		</MenuItem>
																	))
																) : (
																	<div>
																		<span className="no-data-found-wrapper select-box">
																			<i className="bi bi-exclamation-octagon icon-lg" />{" "}
																			{t("common.global.noDataFound")}
																		</span>
																	</div>
																)}
															</Select>
															<FormHelperText
																children={
																	<span>
																		{formik.touched.stages &&
																		formik.errors.stages &&
																		formik.touched.stages.length > 0 &&
																		formik.errors.stages.length > 0 &&
																		formik.touched.stages[index]?.type &&
																		formik.errors.stages[index]?.type
																			? formLocalizedHelperText(
																					formik.errors.stages[index].type
																			  )
																			: ""}
																	</span>
																}
																error={
																	formik.touched.stages &&
																	formik.errors.stages &&
																	formik.touched.stages.length > 0 &&
																	formik.errors.stages.length > 0 &&
																	formik.touched.stages[index]?.type &&
																	Boolean(formik.errors.stages[index]?.type)
																}
															/>
														</FormControl>
													</TableCell>
													<TableCell>
														<Fab
															color="error"
															variant="action"
															aria-label="delete"
															onClick={() => handleDeleteRow(index)}
														>
															<i className="bi bi-trash3" />
														</Fab>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<FormHelperText
								children={
									<span>
										{formik.touched.stages &&
											formLocalizedHelperText(formik.errors.stages)}
									</span>
								}
								error={formik.touched.stages && Boolean(formik.errors.stages)}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<div className="text-end mb-2 mt-4">
								<Button
									variant="outlined"
									color="success"
									onClick={() => handleAddNewAccessoriesRow()}
								>
									<i className="bi bi-plus-circle text-base mr-1" />
									{t("common.button.add")}
								</Button>
							</div>
							<Box className="base-table flex flex-col">
								<TableContainer className="flex-auto h-full">
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.accessoryName")}</TableCell>
												<TableCell>
													{t("common.table.accessoryNumber")}
												</TableCell>
												<TableCell>{t("common.table.actions")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{formik.values.accessories.map((item, index) => (
												<TableRow key={`accessories-row-${index}`}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<TextField
															color="formColor"
															variant="outlined"
															fullWidth
															id={`accessories-name-${index}`}
															name={`accessories-name-${index}`}
															label={t("common.fields.accessoryName")}
															error={
																formik.touched.accessories &&
																formik.errors.accessories &&
																formik.touched.accessories.length > 0 &&
																formik.errors.accessories.length > 0 &&
																formik.touched.accessories[index]?.name &&
																Boolean(formik.errors.accessories[index]?.name)
															}
															helperText={
																formik.touched.accessories &&
																formik.errors.accessories &&
																formik.touched.accessories.length > 0 &&
																formik.errors.accessories.length > 0 &&
																formik.touched.accessories[index]?.name &&
																formik.errors.accessories[index]?.name
																	? formLocalizedHelperText(
																			formik.errors.accessories[index].name
																	  )
																	: ""
															}
															value={formik.values.accessories[index].name}
															onChange={(event) => {
																formik.setFieldValue(
																	`accessories.${index}.name`,
																	event.target.value,
																	true
																)
															}}
															autoComplete="off"
														/>
													</TableCell>
													<TableCell>
														<NumericFormat
															id={`accessories-number-${index}`}
															name={`accessories-number-${index}`}
															label={t("common.fields.accessoryNumber")}
															value={formik.values.accessories[index].number}
															onChange={(event) => {
																let formattedValue =
																	event.target.value &&
																	parseFloat(
																		event.target.value.split(" ").join("")
																	)
																formik.setFieldValue(
																	`accessories.${index}.number`,
																	formattedValue,
																	true
																)
															}}
															error={
																formik.touched.accessories &&
																formik.errors.accessories &&
																formik.touched.accessories.length > 0 &&
																formik.errors.accessories.length > 0 &&
																formik.touched.accessories[index]?.number &&
																Boolean(
																	formik.errors.accessories[index]?.number
																)
															}
															helperText={
																formik.touched.accessories &&
																formik.errors.accessories &&
																formik.touched.accessories.length > 0 &&
																formik.errors.accessories.length > 0 &&
																formik.touched.accessories[index]?.number &&
																formik.errors.accessories[index]?.number
																	? formLocalizedHelperText(
																			formik.errors.accessories[index].number
																	  )
																	: ""
															}
															color="formColor"
															variant="outlined"
															fullWidth
															customInput={TextField}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={3}
															decimalSeparator="."
														/>
													</TableCell>
													<TableCell>
														<Fab
															color="error"
															variant="action"
															aria-label="delete"
															onClick={() => handleDeleteRowAccessories(index)}
														>
															<i className="bi bi-trash3" />
														</Fab>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<FormHelperText
								children={
									<span>
										{formik.touched.stages &&
											formLocalizedHelperText(formik.errors.stages)}
									</span>
								}
								error={formik.touched.stages && Boolean(formik.errors.stages)}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ProductAddEditModal
