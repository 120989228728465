import { ListItemIcon, Menu, MenuItem } from "@mui/material"
import useAuth from "hooks/useAuth"
import useNavigationByRole from "hooks/useNavigationByRole"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import getLabelByTypeList from "utils/getLabelByTypeList"

const ProfileMenu = () => {
	const [{ user, logout }] = useAuth()
	const { navigateFn } = useNavigationByRole()
	const [anchorEl, setAnchorEl] = useState(null)
	const { t } = useTranslation()
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const signOut = async () => {
		await logout()
	}

	return (
		<div className="account">
			<div className="avatar-name" onClick={handleClick}>
				{user?.user?.name[0]}
			</div>

			<div className="user-full-name-box">
				<span>{user?.user?.name}</span>
				<span className="user-role-box">
					{user?.user?.role &&
						getLabelByTypeList(ROLE_TYPE_LIST, user?.user?.role)}
				</span>
			</div>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				disableScrollLock={true}
				transformOrigin={{ horizontal: "center", vertical: "top" }}
				anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
			>
				<MenuItem onClick={() => navigateFn("/BASE/profile")}>
					<ListItemIcon>
						<i className="bi bi-person text-xl text-base-color" />
					</ListItemIcon>
					{t("sidebar.profile")}
				</MenuItem>
				<MenuItem onClick={signOut}>
					<ListItemIcon>
						<i className="bi bi-box-arrow-left text-xl text-base-color" />
					</ListItemIcon>
					{t("sidebar.actions.logout")}
				</MenuItem>
			</Menu>
		</div>
	)
}

export default ProfileMenu
