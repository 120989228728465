import { Button } from "@mui/material"
import { PaintCameIcon } from "components/ui/icons"
import BaseTable from "components/ui/tables/BaseTable"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import GetPaintedFabricModal from "./GetPaintedFabricModal"

const PaintSent = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [openGetPaintedFabricModal, setOpenGetPaintedFabricModal] =
		useState(false)
	const [itemData, setItemData] = useState("")

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("paint.sent.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14"></div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
							className="!mr-2"
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>

			<div className="component-table-wrapper">
				<BaseTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="warehouse/colour/sends"
					emitTableColumns={{ open, setOpen }}
					tableName="paintSent"
					headCells={[
						{ code: "id", label: "#" },
						{ code: "material", label: t("common.table.fabricName") },
						{ code: "threads", label: t("common.table.fabricThreads") },
						{ code: "rulon", label: t("common.table.allRulons") },
						{ code: "leftrulon", label: t("common.table.leftRulon") },
						{ code: "gramaji", label: t("common.table.fabricGramaji") },
						{ code: "pusfine", label: t("common.table.fabricPusfine") },
						{ code: "hom", label: t("common.table.hom") },
						{ code: "color", label: t("common.table.color") },
						{ code: "company", label: t("common.table.paintCompany") },
						{ code: "send_date", label: t("common.table.sendDate") }
					]}
					columns={[
						{ code: "id" },
						{ code: "material", type: "nested", childStr: "name" },
						{
							code: "material",
							type: "nestedArray",
							childArrKey: "threads",
							childArrItemKey: "name.name"
						},
						{ code: "rulon" },
						{ code: "leftrulon" },
						{
							code: "material",
							type: "nestedNumberFormat",
							childStr: "gramaji",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{
							code: "material",
							type: "nestedNumberFormat",
							childStr: "pusfine",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{
							code: "hom",
							type: "numberFormat",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: ""
						},
						{ code: "color" },
						{ code: "company", type: "nested", childStr: "name" },
						{ code: "send_date", type: "dateTime" }
					]}
					actionModal={{
						iconComponent: <PaintCameIcon className="w-5" />,
						btnColor: "info",
						setOpen: setOpenGetPaintedFabricModal,
						setData: setItemData
					}}
				/>
			</div>

			{openGetPaintedFabricModal && (
				<GetPaintedFabricModal
					open={openGetPaintedFabricModal}
					setOpen={setOpenGetPaintedFabricModal}
					setRefetch={setRefetch}
					itemData={itemData}
					setItemData={setItemData}
				/>
			)}
		</div>
	)
}

export default PaintSent
