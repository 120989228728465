import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import formLocalizedHelperText from "utils/formLocalizedHelperText"

const FormAutocompleteCustomNestedItemField = ({
	formik,
	fieldName,
	path,
	label,
	itemValue = "id",
	itemName = "name",
	readOnly = false,
	disabled = false,
	pathChangeable = false
}) => {
	const {t} = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: fieldName + "Select",
		queryFn: async function () {
			const response = await axiosPrivate.get(path)
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		if (pathChangeable) {
			refetch()
			formik.setFieldValue(fieldName, "", true)
		}
	}, [path])

	const getObjField = (obj, str) => {
		if (obj) {
			let a = str.split(".")
			let b = obj
			for (let i = 0; i < a.length; i++) {
				b = b[a[i]]
			}
			return b
		}
		return ""
	}

	return (
		<Fragment>
			<Autocomplete
				id={`${fieldName}-autocomplete`}
				options={data || []}
				disableClearable
				fullWidth
				loading={isLoading || isFetching}
				noOptionsText={t("common.global.noDataFound")}
				getOptionLabel={(option) =>
					option ? getObjField(option, itemName) : ""
				}
				onChange={(event, value) => {
					formik.setFieldValue(
						fieldName,
						value[itemValue] ? value[itemValue] : "",
						true
					)
				}}
				readOnly={readOnly}
				disabled={disabled}
				loadingText={
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				}
				renderInput={(params) => (
					<TextField
						{...params}
						color="formColor"
						variant="outlined"
						label={label}
						error={
							formik.touched[fieldName] && Boolean(formik.errors[fieldName])
						}
						helperText={
							formik.touched[fieldName] &&
							formLocalizedHelperText(formik.errors[fieldName])
						}
						autoComplete="off"
					/>
				)}
			/>
		</Fragment>
	)
}

export default FormAutocompleteCustomNestedItemField
