import CLIENT_TYPE from "./clientTypeList"
import PAYMENT_TYPE from "./paymentTypeList"
import ROLE_TYPE_LIST from "./roleTypeList"

const clientTypeVariants = [
	{
		code: CLIENT_TYPE.PHYSICAL.code,
		label: CLIENT_TYPE.PHYSICAL.label,
		color: "secondary"
	},
	{
		code: CLIENT_TYPE.LEGAL.code,
		label: CLIENT_TYPE.LEGAL.label,
		color: "warning"
	}
]

const paymentTypeVariants = [
	{
		code: PAYMENT_TYPE.CASH.code,
		label: PAYMENT_TYPE.CASH.label,
		color: "success"
	},
	{
		code: PAYMENT_TYPE.P2P.code,
		label: PAYMENT_TYPE.P2P.label,
		color: "info"
	},
	{
		code: PAYMENT_TYPE.BANK.code,
		label: PAYMENT_TYPE.BANK.label,
		color: "warning"
	},
	{
		code: PAYMENT_TYPE.CARD.code,
		label: PAYMENT_TYPE.CARD.label,
		color: "secondary"
	},
	{
		code: PAYMENT_TYPE.ACT.code,
		label: PAYMENT_TYPE.ACT.label,
		color: "error"
	}
]

const roleTypeVariants = [
	{
		code: ROLE_TYPE_LIST.ADMIN.code,
		label: ROLE_TYPE_LIST.ADMIN.label,
		color: "secondary"
	},
	{
		code: ROLE_TYPE_LIST.STOREKEEPER.code,
		label: ROLE_TYPE_LIST.STOREKEEPER.label,
		color: "warning"
	},
	{
		code: ROLE_TYPE_LIST.MANAGER.code,
		label: ROLE_TYPE_LIST.MANAGER.label,
		color: "error"
	},
	{
		code: ROLE_TYPE_LIST.WORKER.code,
		label: ROLE_TYPE_LIST.WORKER.label,
		color: "success"
	}
]

export { clientTypeVariants, paymentTypeVariants, roleTypeVariants }
