import {
	CircularProgress,
	IconButton,
	InputAdornment,
	TableCell,
	TextField
} from "@mui/material"
import SimpleCurrencyField from "components/ui/simple-fields/numeric-format/SimpleCurrencyField"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import formLocalizedHelperText from "utils/formLocalizedHelperText"

const ProductionStageTableItem = ({
	index,
	stageData = "",
	formik,
	staffsList = [],
	setStaffsList = () => {}
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [isLoading, setIsLoading] = useState(false)

	const handleSearchStaffByNumber = async () => {
		let newStaffList = JSON.parse(JSON.stringify(staffsList))
		try {
			setIsLoading(true)
			let response = await axiosPrivate.get(
				`/data/getstaff/${formik.values.stages[index].staffNumber}`
			)
			if (response && response.data && response.data?.data) {
				if (formik.values.stages[index].staff_id !== response.data?.data?.id) {
					newStaffList[index] = {
						id: response.data?.data?.id,
						name: response.data?.data?.name
					}
					formik.setFieldValue(
						`stages.${index}.staff_id`,
						response.data?.data?.id,
						true
					)
				}
			} else {
				newStaffList[index] = { id: "", name: "", notFound: true }
				formik.setFieldValue(`stages.${index}.staff_id`, "", true)
			}
			setIsLoading(false)
			setStaffsList(newStaffList)
		} catch (error) {
			console.log(error)
			newStaffList[index] = { id: "", name: "", notFound: true }
			formik.setFieldValue(`stages.${index}.staff_id`, "", true)
			setStaffsList(newStaffList)
			setIsLoading(false)
		}
	}

	return (
		<Fragment>
			<TableCell>{index + 1}</TableCell>
			<TableCell>
				<TextField
					color="formColor"
					variant="outlined"
					fullWidth
					id={`stage-name-${index}`}
					name={`stage-name-${index}`}
					label={t("common.fields.stageName")}
					value={stageData?.name}
					autoComplete="off"
					InputProps={{
						disabled: true,
						readOnly: true
					}}
				/>
			</TableCell>
			<TableCell>
				<SimpleCurrencyField
					name={`stage-sum-${index}`}
					label={t("common.fields.stageSum")}
					value={stageData?.sum}
					disabled={true}
					readOnly={true}
				/>
			</TableCell>
			<TableCell>
				<TextField
					color="formColor"
					variant="outlined"
					fullWidth
					id={`staff-name-${index}`}
					name={`staff-name-${index}`}
					label={t("common.fields.tabelNumber")}
					error={
						formik.touched.stages &&
						formik.errors.stages &&
						formik.touched.stages.length > 0 &&
						formik.errors.stages.length > 0 &&
						formik.touched.stages[index]?.staffNumber &&
						Boolean(formik.errors.stages[index]?.staffNumber)
					}
					helperText={
						formik.touched.stages &&
						formik.errors.stages &&
						formik.touched.stages.length > 0 &&
						formik.errors.stages.length > 0 &&
						formik.touched.stages[index]?.staffNumber &&
						formik.errors.stages[index]?.staffNumber
							? formLocalizedHelperText(formik.errors.stages[index].staffNumber)
							: ""
					}
					value={formik.values.stages[index].staffNumber}
					onChange={(event) => {
						formik.setFieldValue(
							`stages.${index}.staffNumber`,
							event.target.value,
							true
						)
					}}
					onKeyDown={(event) => {
						if (event.keyCode === 13) {
							handleSearchStaffByNumber()
						}
					}}
					autoComplete="off"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end" className="custom-endAdornment">
								{isLoading && (
									<IconButton variant="onlyIcon">
										<CircularProgress size={15} color="inherit" />
									</IconButton>
								)}
							</InputAdornment>
						),
						disabled: isLoading,
						readOnly: isLoading
					}}
				/>
				{staffsList[index]?.name && (
					<div className="absolute -mt-1 text-gray-600">
						<span className="font-semibold">
							{t("common.fields.workerName")}:
						</span>{" "}
						{staffsList[index]?.name}
					</div>
				)}
				{staffsList[index]?.notFound && (
					<div className="absolute -mt-1 text-red-600">
						{t("fabric.noWorkerFound")}
					</div>
				)}
			</TableCell>
		</Fragment>
	)
}

export default ProductionStageTableItem
