import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import { NumericFormat } from "react-number-format"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import FormTextField from "components/ui/form/FormTextField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import SimpleAutocompleteRemoteField from "components/ui/simple-fields/select/SimpleAutocompleteRemoteField"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"

const validationSchema = yup.object({
	/* name: yup
		.string()
		.min(0, { label: "fabric.validation.nameMin", value: 0 })
		.required("fabric.validation.name"),
	amount: yup
		.number()
		.min(0, { label: "fabric.validation.amountMin", value: 0 })
		.required("fabric.validation.amount"),
	pusfine: yup
		.number()
		.min(0, { label: "fabric.validation.pusfineMin", value: 0 })
		.required("fabric.validation.pusfine"),
	gramaji: yup
		.number()
		.min(0, { label: "fabric.validation.gramajiMin", value: 0 })
		.required("fabric.validation.gramaji"), */
/* 	threads: yup
		.array()
		.of(
			yup.object({
				id: yup.number().required("fabric.validation.threadId"),
				amount: yup
					.number()
					.min(0, { label: "fabric.validation.threadAmountMin", value: 0 })
					.required("fabric.validation.threadAmount")
			})
		)
		.min(1, { label: "fabric.validation.threadsMin", value: 1 })
		.required("fabric.validation.threads") */
})

const SaleAddEdit = () => {
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()
	const { setComponent } = useTopPanel()
	const [selectedThreads, setSelectedThreads] = useState([])
	const [threadsList, setThreadsList] = useState([])
	const axiosPrivate = useAxiosPrivate()

	const formik = useFormik({
		initialValues: {
            company_id: "",
            comment: "",
			threads: [
				{
					id: "",
					amount: "",
                    price: ""
				}
			]
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = JSON.parse(JSON.stringify(values))
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				"/admin/sale/sale",
			)
		}
	})

	const handleChangeThreads = (value) => {
		value.forEach((item, index) => {
			formik.setFieldValue(
				`threads.${index}`,
				{ id: item, amount: formik.values.threads[index]?.amount || 0 },
				true
			)
		})
		let notIncludedItems = selectedThreads.filter(
			(item) => !value.includes(item)
		)
		if (notIncludedItems && notIncludedItems.length > 0) {
			formik.setFieldValue(
				"threads",
				formik.values.threads.filter((el) => !notIncludedItems.includes(el.id)),
				true
			)
		}
		setSelectedThreads(value)
	}

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				<span>{t("fabric.addTitle")}</span>
			</div>
		)
	}, [])

	return (
		<div className="component-add-edit-wrapper mx-4">
			<div className="component-add-edit-body mt-3">
				<form onSubmit={formik.handleSubmit}>
				 	<Grid
						container
						spacing={{ xs: 2, sm: 2, lg: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<FormAutocompleteField
								label={t("common.fields.productName")}
								fieldName="company_id"
								formik={formik}
								path="/data/customcompanies"
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<TextField
								label={t("common.fields.fabricAmount")}
								fieldName="comment"
								formik={formik}
							/>
						</Grid>
					</Grid> 

					<Grid
						container
						spacing={{ xs: 2, sm: 2, lg: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<SimpleAutocompleteRemoteField
								label={t("common.fields.threads")}
								name="threads"
								itemValue="id"
								itemLabel="name"
								changeFn={handleChangeThreads}
								path="/data/units"
								setData={setThreadsList}
							/>
						</Grid>

						<Grid item={true} lg={10} sm={12} xs={12}>
							<Box className="base-table flex flex-col">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 500, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.product")}</TableCell>
                                                <TableCell>{t("common.table.productleft")}</TableCell>
												<TableCell>{t("common.table.productsale")}</TableCell>
                                                <TableCell>{t("common.table.productprice")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{selectedThreads &&
												selectedThreads.length > 0 &&
												selectedThreads.map((item, index) => (
													<TableRow key={`thread-row-${index}`}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>
															<TextField
																color="formColor"
																variant="outlined"
																fullWidth
																id={`thread-name-${index}`}
																name={`thread-name-${index}`}
																label={t("common.fields.threadName")}
																value={
																	threadsList.find((el) => el.id === item)?.name
																}
															/>
														</TableCell>
                                                        <TableCell>
															<NumericFormat
																value={
																	threadsList.find((el) => el.id === item)?.left
																}
																displayType={"text"}
																allowNegative={false}
																thousandSeparator={" "}
																decimalScale={1}
																decimalSeparator="."
																className="bg-transparent whitespace-nowrap"
															/>
														</TableCell>
														<TableCell>
															<NumericFormat
																id={`thread-amount-${index}`}
																name={`thread-amount-${index}`}
																label={t("common.fields.productamount")}
																value={formik.values.threads[index].amount}
																onChange={(event) => {
																	let formattedValue =
																		event.target.value &&
																		parseFloat(
																			event.target.value.split(" ").join("")
																		)
																	formik.setFieldValue(
																		`threads.${index}.amount`,
																		formattedValue,
																		true
																	)
																}}
																error={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.amount &&
																	Boolean(formik.errors.threads[index]?.amount)
																}
																helperText={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.amount &&
																	formik.errors.threads[index]?.amount
																		? formLocalizedHelperText(
																				formik.errors.threads[index].amount
																		  )
																		: ""
																}
																color="formColor"
																variant="outlined"
																fullWidth
																customInput={TextField}
																allowNegative={false}
																thousandSeparator={" "}
																decimalScale={0}
																decimalSeparator="."
															/>
														</TableCell>
                                                        <TableCell>
															<NumericFormat
																id={`thread-price-${index}`}
																name={`thread-price-${index}`}
																label={t("common.fields.productprice")}
																value={formik.values.threads[index].price}
																onChange={(event) => {
																	let formattedValue =
																		event.target.value &&
																		parseFloat(
																			event.target.value.split(" ").join("")
																		)
																	formik.setFieldValue(
																		`threads.${index}.price`,
																		formattedValue,
																		true
																	)
																}}
																error={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.price &&
																	Boolean(formik.errors.threads[index]?.price)
																}
																helperText={
																	formik.touched.threads &&
																	formik.errors.threads &&
																	formik.touched.threads.length > 0 &&
																	formik.errors.threads.length > 0 &&
																	formik.touched.threads[index]?.price &&
																	formik.errors.threads[index]?.price
																		? formLocalizedHelperText(
																				formik.errors.threads[index].price
																		  )
																		: ""
																}
																color="formColor"
																variant="outlined"
																fullWidth
																customInput={TextField}
																allowNegative={false}
																thousandSeparator={" "}
																decimalScale={0}
																decimalSeparator="."
															/>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons isSubmitting={isSubmitting} />
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	)
}

export default SaleAddEdit
