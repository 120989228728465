import useTopPanel from "hooks/useTopPanel"
import React, { useEffect } from "react"
import "moment/locale/uz-latn"
import "moment/locale/ru"
import "moment/locale/uz"
import { useTranslation } from "react-i18next"

const Dashboard = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("dashboard.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="dashboard-wrapper">
			<div className="py-6">Bosh sahifa</div>
		</div>
	)
}

export default Dashboard
