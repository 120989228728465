import { TextField } from "@mui/material"
import { NumericFormat } from "react-number-format"

const SimpleCurrencyField = ({
	value,
	setValue,
	label,
	name,
	prefix = "",
	readOnly = false,
	disabled = false,
	error = false,
	helperText = ""
}) => {
	return (
		<NumericFormat
			id={`${name}-currency-field`}
			name={name}
			label={label}
			value={value}
			onChange={(event) => {
				let formattedValue =
					event.target.value && parseInt(event.target.value.split(" ").join(""))
				setValue(formattedValue)
			}}
			error={error}
			helperText={helperText}
			color="formColor"
			variant="outlined"
			fullWidth
			customInput={TextField}
			allowNegative={false}
			InputProps={{
				readOnly: readOnly,
				disabled: disabled
			}}
			thousandSeparator={" "}
			decimalScale={3}
			decimalSeparator="."
			prefix={prefix}
		/>
	)
}

export default SimpleCurrencyField
