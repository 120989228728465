import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	ButtonBase
} from "@mui/material"
import React, { useState } from "react"
import { useMatch, useLocation, Link } from "react-router-dom"
import NavBarLinkItem from "./NavBarLinkItem"
import { motion } from "framer-motion"
import { fadeUp } from "utils/motion"

const NavBarLinkAccordion = ({
	linkData = {
		code: "",
		title: ""
	},
	accordionItems = [],
	iconName = null,
	customIconComponent = null,
	subIconName = null,
	delay = 0,
	customTextComponent = null
}) => {
	const [expand, setExpand] = useState(false)

	const location = useLocation()

	const matchAccordionLink = useMatch({
		path:
			location.pathname.split(/-|\//).length > 2 &&
			location.pathname.split(/-|\//)[2] === linkData.code
				? location.pathname
				: `/${linkData.code}`
	})

	const linkClass = (className, isMatch, isExpanded) => {
		let newClassName = className
		newClassName = isMatch ? newClassName + " active-link" : newClassName
		newClassName = isExpanded ? newClassName + " expanded" : newClassName
		return newClassName
	}

	return (
		<motion.li
			variants={fadeUp(30, "spring", delay, 1)}
			initial="hidden"
			animate="show"
			viewport={{ once: true, amount: 0.25 }}
		>
			<Accordion
				expanded={expand}
				onChange={() => setExpand((prev) => (prev = !prev))}
				sx={{
					boxShadow: "none"
				}}
				className={linkClass(
					"navigation-link-accordion",
					matchAccordionLink,
					expand
				)}
			>
				<AccordionSummary
					aria-controls={`${linkData.code}-content`}
					id={`${linkData.code}-header`}
					expandIcon={
						<i className="bi bi-chevron-right accordion-expand-icon" />
					}
				>
					<ButtonBase
						className={linkClass(
							"w-full navigation-button",
							matchAccordionLink,
							expand
						)}
					>
						<div className="navigation-link-bottom">
							{iconName && <i className={iconName + " link-icon"} />}
							{subIconName && <i className={subIconName + " link-sub-icon"} />}
							{customIconComponent ? (
								<div className="link-icon">{customIconComponent}</div>
							) : (
								""
							)}
							{customTextComponent ? (
								customTextComponent
							) : (
								<span>{linkData.title}</span>
							)}
						</div>
						<div className="navigation-link-top">
							{iconName && <i className={iconName + " link-icon"} />}
							{subIconName && <i className={subIconName + " link-sub-icon"} />}
							{customIconComponent ? (
								<div className="link-icon">{customIconComponent}</div>
							) : (
								""
							)}
							{customTextComponent ? (
								customTextComponent
							) : (
								<span>{linkData.title}</span>
							)}
						</div>
					</ButtonBase>
				</AccordionSummary>
				<AccordionDetails>
					<ul className="navigation-link-accordion-list-wrapper">
						{accordionItems.map((item, index) => (
							<NavBarLinkItem
								key={`accordion-${linkData.code}-item-${index}`}
								{...item}
							/>
						))}
					</ul>
				</AccordionDetails>
			</Accordion>
		</motion.li>
	)
}

export default NavBarLinkAccordion
