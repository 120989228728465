import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormSelectField from "components/ui/form/FormSelectField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
	company_id: yup.string().required("sendToService.modal.validation.company"),
	number: yup
		.number()
		.min(0, {
			label: "sendToService.modal.validation.numberMin",
			value: 0
		})
		.required("sendToService.modal.validation.number"),
	price: yup.number().required("sendToService.modal.validation.price"),
	size: yup.string().required("sendToService.modal.validation.size"),
	name: yup.string().required("sendToService.modal.validation.name")
})

const SendToServiceModal = (props) => {
	const { open, setOpen, setRefetch, itemData, setItemData } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			company_id: "",
			number: "",
			price: "",
			size: "",
			name: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				{ ...values },
				`/staff/unit/service/${itemData.id}`,
				{
					title: t("sendToService.modal.notification.sent", {
						name: itemData?.name
					})
				},
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setItemData("")
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>
					{t("sendToService.modal.title", {
						name: itemData?.product?.name
					})}
				</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				{parseFloat(itemData?.swegging) <= 0 ? (
					<div className="py-2">
						<Alert severity="warning">
							{t("sendToService.modal.alerts.couldNotSend")}
						</Alert>
					</div>
				) : (
					<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
						<Grid
							container
							spacing={{ xs: 2, sm: 3, lg: 3 }}
							rowSpacing={1}
							columns={{ xs: 12, sm: 12, lg: 12 }}
						>
							<Grid item={true} sm={6} xs={12}>
								<FormSelectField
									label={t("common.fields.company")}
									fieldName="company_id"
									formik={formik}
									path={"/data/service-companies"}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormCurrencyField
									label={t("common.fields.amount")}
									fieldName="number"
									formik={formik}
									decimalScale={0}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormCurrencyField
									label={t("common.fields.price")}
									fieldName="price"
									formik={formik}
									decimalScale={0}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormTextField
									label={t("common.fields.size")}
									fieldName="size"
									formik={formik}
								/>
							</Grid>

							<Grid item={true} sm={12} xs={12}>
								<FormTextField
									label={t("common.fields.name")}
									fieldName="name"
									formik={formik}
								/>
							</Grid>

							<Grid item={true} sm={12} xs={12}>
								<FormActionButtons
									isSubmitting={isSubmitting}
									formType="dialog"
									setOpen={setOpen}
									reset={formik.resetForm}
								/>
							</Grid>
						</Grid>
					</form>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default SendToServiceModal
