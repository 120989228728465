import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

const SimpleRemoteSelectChangeFnField = ({
	path,
	label,
	name,
	value,
	changeFn = () => {},
	readOnly = false,
	disabled = false,
	pathChangeable = false
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: name + "Select",
		queryFn: async function () {
			const response = await axiosPrivate.get(path)
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		if (pathChangeable) {
			refetch()
			changeFn("")
		}
	}, [path])

	return (
		<FormControl fullWidth color="formColor">
			<InputLabel id={`${name}-label`}>{label}</InputLabel>
			<Select
				labelId={`${name}-label`}
				id={`${name}-select`}
				label={label}
				onChange={(event) => changeFn(event.target.value)}
				value={isLoading || isFetching ? "" : value}
				color="formColor"
				variant="outlined"
				readOnly={readOnly}
				disabled={disabled}
				role="presentation"
				MenuProps={{
					id: `${name}-select-menu`,
					disableScrollLock: true,
					PaperProps: {
						style: {
							maxHeight: 300
						}
					}
				}}
			>
				{isLoading || isFetching ? (
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				) : data && data.length > 0 ? (
					data.map((item, index) => (
						<MenuItem value={item.id} key={index + 1}>
							{item.name}
						</MenuItem>
					))
				) : (
					<div>
						<span className="no-data-found-wrapper select-box">
							<i className="bi bi-exclamation-octagon icon-lg" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</Select>
		</FormControl>
	)
}

export default SimpleRemoteSelectChangeFnField
