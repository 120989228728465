import { Button } from "@mui/material"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BaseTable from "components/ui/tables/BaseTable"
import { useNavigate } from "react-router-dom"
import ProductionFromOneStageToAnotherModal from "./ProductionFromOneStageToAnotherModal"

const Sale = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [openSendFabricToPaintingModal, setOpenSendFabricToPaintingModal] =
		useState(false)
	const [itemData, setItemData] = useState("")
	const navigate = useNavigate()
	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("sale.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14"></div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="success"
							className="!mx-2"
							onClick={() => {
								navigate("/admin/sale/add")
							}}
						>
							<i className="bi bi-plus-circle" />
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>

			<div className="component-table-wrapper">
				<BaseTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="admin/sale/index"
					emitTableColumns={{ open, setOpen }}
					tableName="fabrics"
					headCells={[
						{ code: "id", label: "#" },
						{ code: "name", label: t("common.table.companyName") },
						{ code: "date", label: t("common.table.date") },
						{ code: "sum", label: t("common.table.sum") }
					]}
					columns={[
						{ code: "id" },
						{ code: "name" },
						{ code: "date"},
						{
							code: "sum",
							type: "numberFormat",
							decimalScale: 1,
							thousandSeparator: "",
							suffix: " $"
						},
					]}
					actionModal={{
						icon: "bi bi-info",
						btnColor: "info",
						setOpen: setOpenSendFabricToPaintingModal,
						setData: setItemData
					}}
				/>
			</div>

			{openSendFabricToPaintingModal && (
				<ProductionFromOneStageToAnotherModal
					open={openSendFabricToPaintingModal}
					setOpen={setOpenSendFabricToPaintingModal}
					itemData={itemData}
					setItemData={setItemData}
					setRefetch={setRefetch}
				/>
			)} 
		</div>
	)
}

export default Sale
