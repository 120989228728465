import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormNumberField from "components/ui/form/FormNumberField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import * as yup from "yup"

const GetPaintedFabricModal = (props) => {
	const { open, setOpen, setRefetch, itemData, setItemData } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const validationSchema = yup.object({
		party: yup.string().required("paint.sent.getPaintedModal.validation.party"),
		rulon: yup.number().required("paint.sent.getPaintedModal.validation.rulon"),
		brutto: yup
			.string()
			.required("paint.sent.getPaintedModal.validation.brutto"),
		netto: yup.string().required("paint.sent.getPaintedModal.validation.netto"),
		price: yup.number().required("paint.sent.getPaintedModal.validation.price"),
		rasxod: yup
			.number()
			.min(0, {
				label: "paint.sent.getPaintedModal.validation.expenseMin",
				value: 0
			})
			.required("paint.sent.getPaintedModal.validation.expense")
	})

	console.log("itemData = ", itemData)

	const formik = useFormik({
		initialValues: {
			party: "",
			rulon: "",
			brutto: "",
			netto: "",
			price:
				itemData?.price && parseFloat(itemData?.price) > 0
					? itemData?.price
					: "",
			rasxod: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				values,
				`/warehouse/colour/come/${itemData?.id}`,
				{
					title: t("paint.sent.notification.success")
				},
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		setItemData("")
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<div>
					<div>{t("paint.sent.getPaintedModal.title")}</div>
					<div className="text-gray-600 text-base rounded-lg my-shadow-1 px-3 py-2 mt-2">
						<div>
							{t("common.table.fabricThreads")}:{" "}
							{itemData?.material?.threads &&
								itemData?.material?.threads.length > 0 &&
								itemData?.material?.threads.map(
									(el, index) =>
										`"${el?.name?.name}"${
											index !== itemData?.material?.threads.length - 1
												? ", "
												: ""
										}`
								)}
						</div>
						<div>
							{t("common.table.hom")}:{" "}
							<NumericFormat
								value={parseFloat(itemData?.hom)}
								displayType={"text"}
								allowNegative={false}
								thousandSeparator={" "}
								decimalScale={1}
								decimalSeparator="."
								className="bg-transparent whitespace-nowrap"
							/>
						</div>
					</div>
				</div>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<FormTextField
								label={t("common.fields.party")}
								fieldName="party"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormNumberField
								label={t("common.fields.rulon")}
								fieldName="rulon"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.brutto")}
								fieldName="brutto"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.netto")}
								fieldName="netto"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.paintPrice")}
								fieldName="price"
								formik={formik}
								decimalScale={3}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								label={t("common.fields.expense")}
								fieldName="rasxod"
								formik={formik}
								decimalScale={0}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default GetPaintedFabricModal
