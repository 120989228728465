import { Button, CircularProgress, Grid } from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormSelectField from "components/ui/form/FormSelectField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useFormSubmit from "hooks/useFormSubmit"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import TypeAddEditModal from "./TypeAddEditModal"
import useAuth from "hooks/useAuth"

const validationSchema = yup.object({
	name: yup
		.string()
		.min(3, { label: "settings.companies.validation.nameMin", value: 3 })
		.required("settings.companies.validation.name"),
	type: yup.number().required("settings.companies.validation.type")
})

const CompanyAddEdit = () => {
	const { id } = useParams()
	const { t } = useTranslation()
	const [{ user }] = useAuth()
	const axiosPrivate = useAxiosPrivate()
	const { submit, isSubmitting } = useFormSubmit()
	const [hasError, setHasError] = useState(false)
	const [typeOptionsRefetch, setTypeOptionsRefetch] = useState(false)
	const [openTypeModal, setOpenTypeModal] = useState(false)

	const { setComponent } = useTopPanel()

	const formik = useFormik({
		initialValues: {
			name: "",
			type: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if (id)
				submit(
					{ type: "put", contentType: "simple" },
					values,
					"/admin/company",
					values.name,
					id
				)
			else
				submit(
					{ type: "post", contentType: "simple" },
					values,
					"/admin/company",
					values.name
				)
		}
	})
	const { isLoading, isFetching } = useQuery({
		queryKey: "companySingle",
		queryFn: async function () {
			const response = await axiosPrivate.get(`/admin/company/edit/${id}`)
			return response.data.data
		},
		onSuccess: (data) => {
			formik.setValues({
				name: data.name,
				type: data.type
			})
		},
		enabled: !hasError && !!id,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{id && formik.values.name ? (
					<span>
						{t("settings.companies.editTitle", { value: formik.values.name })}
					</span>
				) : (
					<span>{t("settings.companies.addTitle")}</span>
				)}
			</div>
		)
	}, [formik.values.name])

	return (
		<div className="component-add-edit-wrapper mx-4">
			<div className="component-add-edit-body mt-3">
				{isLoading || isFetching ? (
					<div className="circular-progress-box py-5">
						<CircularProgress size={35} />
					</div>
				) : (
					<form onSubmit={formik.handleSubmit}>
						<Grid
							container
							spacing={{ xs: 2, sm: 3, lg: 3 }}
							rowSpacing={1}
							columns={{ xs: 12, sm: 12, lg: 12 }}
						>
							<Grid item={true} lg={4} sm={6} xs={12}>
								<FormTextField
									label={t("common.fields.companyName")}
									fieldName="name"
									formik={formik}
								/>
							</Grid>

							<Grid item={true} lg={4} sm={6} xs={12}>
								{/* <div className="flex flex-row"> */}
								<FormSelectField
									label={t("common.fields.companyType")}
									fieldName="type"
									formik={formik}
									path={"/data/companytypes"}
									emitRefetch={{
										refetch: typeOptionsRefetch,
										setRefetch: setTypeOptionsRefetch
									}}
								/>
								{/* <Button
										type="button"
										variant="actionLarge"
										className="!ml-2 !mt-2"
										onClick={() => setOpenTypeModal(true)}
									>
										<i className="bi bi-plus-lg" />
									</Button>
								</div> */}
							</Grid>

							<Grid item={true} sm={12} xs={12}>
								<FormActionButtons isSubmitting={isSubmitting} />
							</Grid>
						</Grid>
					</form>
				)}
			</div>

			{openTypeModal && (
				<TypeAddEditModal
					userId={user?.user?.id}
					open={openTypeModal}
					setOpen={setOpenTypeModal}
					setRefetch={setTypeOptionsRefetch}
				/>
			)}
		</div>
	)
}

export default CompanyAddEdit
