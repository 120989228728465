import {
	Box,
	Button,
	CircularProgress,
	Fab,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import BackButton from "components/ui/BackButton"
import BaseTooltipCustomWidth from "components/ui/tooltips/BaseTooltipCustomWidth"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import ProductStageAddEditModal from "./items/ProductStageAddEditModal"
import { stageTypeSelectOptions } from "shared/selectOptionsList"
import ProductAccessoriesModal from "./items/ProductAccessoriesModal"

const ProductView = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [open, setOpen] = useState(false)
	const [openAccessories, setOpenAccessories] = useState(false)
	const [itemData, setItemData] = useState("")
	const { setComponent } = useTopPanel()

	const {
		data: productQuery,
		isLoading,
		isFetching,
		refetch
	} = useQuery({
		queryKey: "productSingle",
		queryFn: async function () {
			const response = await axiosPrivate.get(`/admin/product/edit/${id}`)
			return response.data.data
		},
		onSuccess: (data) => {},
		enabled: !hasError && !!id,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	const getStageTypeById = (typeId) => {
		return stageTypeSelectOptions.find((item) => item.code === typeId)?.label
	}

	useEffect(() => {
		setComponent(
			<div className="flex flex-row items-center">
				<BackButton />
				<div className="text-base-color text-xl font-medium flex flex-row">
					{t("products.view.title")}:
					<BaseTooltipCustomWidth
						arrow={true}
						placement="bottom"
						enterDelay={1000}
						leaveTouchDelay={0}
						title={productQuery?.name}
						width={"800px"}
						fontSize={"1rem"}
					>
						<span className="text-line-1 max-w-[800px] ml-1">
							{productQuery?.name}
						</span>
					</BaseTooltipCustomWidth>
				</div>
			</div>
		)
	}, [productQuery, i18n.language])

	return (
		<div className="component-add-edit-wrapper mx-4 pb-6">
			{isLoading || isFetching ? (
				<div className="circular-progress-box py-5">
					<CircularProgress size={35} />
				</div>
			) : (
				productQuery && (
					<div className="component-add-edit-body mt-3">
						<Grid
							container
							spacing={2}
							columns={{ lg: 24, md: 24, sm: 24, xs: 24 }}
						>
							<Grid item={true} lg={7} md={12} sm={10} xs={24}>
								<img
									src={
										productQuery.image
											? `${process.env.REACT_APP_BACKEND_URL}/${productQuery.image}`
											: ""
									}
									alt={
										productQuery.image ? "product-image" : "product-card-bg-img"
									}
									className="w-full h-full min-h-[400px] object-cover rounded-lg my-shadow-2"
								/>
							</Grid>
							<Grid item={true} lg={8} md={12} sm={14} xs={24}>
								<div className="items-center justify-between my-shadow-2 rounded-lg p-4 w-full h-full mb-4">
									<div className="flex w-full">
										<span className="data-item-title text-base font-medium text-base-color">
											{t("products.view.name")}:
										</span>
										<span className="data-item-value text-base ml-1">
											{productQuery?.name}
										</span>
									</div>
									<div className="flex w-full">
										<span className="data-item-title text-base font-medium text-base-color">
											{t("products.view.price")}:
										</span>
										<span className="data-item-value text-base">
											<span className="ml-1">
												<NumericFormat
													value={productQuery?.price}
													allowNegative={false}
													displayType={"text"}
													thousandSeparator={" "}
													decimalScale={1}
													decimalSeparator="."
													suffix=" $"
												/>
											</span>
										</span>
									</div>
								</div>
							</Grid>
							<Grid item={true} lg={12} sm={24} xs={24}>
								<Box className="base-table h-full mt-2">
									<TableContainer className="flex flex-col flex-auto h-full">
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>№</TableCell>
													<TableCell>{t("common.table.stageName")}</TableCell>
													<TableCell>{t("common.table.stageType")}</TableCell>
													<TableCell>{t("common.table.stageSum")}</TableCell>
													<TableCell>{t("common.table.actions")}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody className="overflow-hidden">
												{productQuery.allstages &&
													productQuery.allstages.length > 0 &&
													productQuery.allstages.map((item, index) => (
														<TableRow key={`stage-row-${index}`}>
															<TableCell>{index + 1}</TableCell>
															<TableCell>{item?.name}</TableCell>
															<TableCell>
																{t(getStageTypeById(item?.type))}
															</TableCell>
															<TableCell>
																<NumericFormat
																	value={item?.sum}
																	allowNegative={false}
																	displayType={"text"}
																	thousandSeparator={" "}
																	decimalScale={1}
																	decimalSeparator="."
																	suffix=" UZS"
																/>
															</TableCell>
															<TableCell>
																<Fab
																	color="warning"
																	variant="action"
																	aria-label="edit"
																	onClick={() => {
																		setOpen(true)
																		setItemData(item)
																	}}
																>
																	<i className="bi bi-pencil-square" />
																</Fab>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
										<div className="text-center mt-auto mb-4 pt-4">
											<Button
												variant="outlined"
												color="success"
												onClick={() => {
													setOpen(true)
													setItemData("")
												}}
											>
												<i className="bi bi-plus-circle text-base mr-1" />
												{t("common.button.add")}
											</Button>
										</div>
									</TableContainer>
								</Box>
							</Grid>

							<Grid item={true} lg={12} md={12} sm={14} xs={24}>
								<Box className="base-table h-full mt-2">
									<TableContainer className="flex flex-col flex-auto h-full">
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>№</TableCell>
													<TableCell>
														{t("common.table.accessoryName")}
													</TableCell>
													<TableCell>
														{t("common.table.accessoryNumber")}
													</TableCell>
													<TableCell>{t("common.table.actions")}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody className="overflow-hidden">
												{productQuery.accessories &&
													productQuery.accessories.length > 0 &&
													productQuery.accessories.map((item, index) => (
														<TableRow key={`accessories-row-${index}`}>
															<TableCell>{index + 1}</TableCell>
															<TableCell>{item?.name}</TableCell>
															<TableCell>
																<NumericFormat
																	value={item?.number}
																	allowNegative={false}
																	displayType={"text"}
																	thousandSeparator={" "}
																	decimalScale={1}
																	decimalSeparator="."
																	suffix=" "
																/>
															</TableCell>
															<TableCell>
																<Fab
																	color="warning"
																	variant="action"
																	aria-label="edit"
																	onClick={() => {
																		setOpenAccessories(true)
																		setItemData(item)
																	}}
																>
																	<i className="bi bi-pencil-square" />
																</Fab>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
										<div className="text-center mt-auto mb-4 pt-4">
											<Button
												variant="outlined"
												color="success"
												onClick={() => {
													setOpenAccessories(true)
													setItemData("")
												}}
											>
												<i className="bi bi-plus-circle text-base mr-1" />
												{t("common.button.add")}
											</Button>
										</div>
									</TableContainer>
								</Box>
							</Grid>
						</Grid>
					</div>
				)
			)}
			{open && (
				<ProductStageAddEditModal
					open={open}
					setOpen={setOpen}
					productId={productQuery?.id}
					itemData={itemData}
					setRefetch={refetch}
					setItemData={setItemData}
				/>
			)}

			{openAccessories && (
				<ProductAccessoriesModal
					open={openAccessories}
					setOpenEdit={setOpenAccessories}
					productId={productQuery?.id}
					itemData={itemData}
					setRefetch={refetch}
					setItemData={setItemData}
				/>
			)}
		</div>
	)
}

export default ProductView
