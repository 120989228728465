import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import NavBarLinkItem from "components/navbar-link/NavBarLinkItem"
import { motion } from "framer-motion"
import { fade } from "utils/motion"
import { IconButton } from "@mui/material"
import BaseTooltip from "./ui/tooltips/BaseTooltip"
import useAuth from "hooks/useAuth"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import { Trans, useTranslation } from "react-i18next"
import logo from "assets/images/logo/logo.svg"
import {
	FabricIcon,
	PaintCameIcon,
	PaintSentIcon,
	ProductionIcon,
	ProductsIcon,
	ThreadIcon
} from "./ui/icons"
import NavBarLinkAccordion from "./navbar-link/NavBarLinkAccordion"

const SidebarPanel = ({ sideBarToggle, setSideBarToggle }) => {
	const [{ user }] = useAuth()
	const { t } = useTranslation()

	const getLogoLink = () => {
		if (user && user?.user?.role) {
			if (user?.user?.role === ROLE_TYPE_LIST.ADMIN.code) {
				return "/admin/dashboard"
			}
		}
		return "#"
	}

	return (
		<div className="sidebar-panel-wrapper my-shadow-2 overflow-y-auto h-full">
			<div className="sidebar-panel-header h-[80px] flex items-center justify-center xs:flex-row mt-3 p-3">
				<Link to={getLogoLink()} className="no-underline">
					<motion.img
						variants={fade({
							direction: "left",
							positionHiddenX: "-30px",
							duration: 0.5
						})}
						initial="hidden"
						animate="show"
						src={logo}
						alt="logo.svg"
						className="w-[120px] xs:m-0 mx-auto"
					/>
				</Link>
				<div className="md:hidden close-btn-wrapper">
					<IconButton
						variant="onlyIcon"
						color="primary"
						onClick={() => setSideBarToggle(false)}
					>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</div>

			<ul className="sidebar-links-wrapper mt-2 p-3 mb-6">
				{user?.user?.role === ROLE_TYPE_LIST.ADMIN.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "admin/dashboard",
								title: t("sidebar.dashboard")
							}}
							iconName="bi bi-house"
							delay={0}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/thread",
								title: t("sidebar.thread")
							}}
							customIconComponent={<ThreadIcon />}
							delay={0.1}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/fabric",
								title: t("sidebar.fabric")
							}}
							customIconComponent={<FabricIcon />}
							delay={0.2}
						/>
						<NavBarLinkAccordion
							linkData={{
								code: "paint",
								title: t("sidebar.paint")
							}}
							iconName="bi bi-paint-bucket !text-2xl"
							accordionItems={[
								{
									linkData: {
										path: "admin/paint-sent",
										title: t("sidebar.paintSent")
									},
									customIconComponent: <PaintSentIcon />
								},
								{
									linkData: {
										path: "admin/paint-came",
										title: t("sidebar.paintCame")
									},
									customIconComponent: <PaintCameIcon />
								}
							]}
							delay={0.3}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/products",
								title: t("sidebar.products")
							}}
							customIconComponent={<ProductsIcon />}
							delay={0.4}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/process",
								title: t("sidebar.process")
							}}
							iconName="bi bi-diagram-3"
							delay={0.5}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/production",
								title: t("sidebar.production")
							}}
							customIconComponent={<ProductionIcon className="no-fill-icon" />}
							delay={0.6}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/send-to-service",
								title: t("sidebar.sendToService")
							}}
							iconName="bi bi-box-arrow-up-right !text-[18px]"
							delay={0.7}
						/>

						<NavBarLinkItem
							linkData={{
								path: "admin/get-from-service",
								title: t("sidebar.getFromService")
							}}
							iconName="bi bi-box-arrow-in-down-left"
							delay={0.8}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/done",
								title: t("sidebar.done")
							}}
							iconName="bi bi-check2-circle"
							delay={0.9}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/sale",
								title: t("sidebar.sale")
							}}
							iconName="bi bi-cart3"
							delay={1}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/salary",
								title: t("sidebar.salary")
							}}
							iconName="bi bi-cash-coin"
							delay={1.1}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/currency",
								title: t("sidebar.currency")
							}}
							iconName="bi bi-coin"
							delay={1.2}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/settings",
								title: t("sidebar.settings")
							}}
							iconName="bi bi-gear"
							delay={1.3}
						/>
					</Fragment>
				)}
			</ul>

			<div className="toggle-btn-wrapper">
				{sideBarToggle ? (
					<BaseTooltip
						enterDelay={2000}
						leaveTouchDelay={0}
						title={
							<div>
								<Trans i18nKey="sidebar.actions.close">
									Yopish uchun <code className="toggle-btn-sign">[</code> ni
									bosing
								</Trans>
							</div>
						}
						arrow={true}
						placement="right"
					>
						<IconButton
							variant="toggle"
							onClick={() => setSideBarToggle(false)}
						>
							<i className="bi bi-caret-left" />
						</IconButton>
					</BaseTooltip>
				) : (
					<BaseTooltip
						enterDelay={2000}
						leaveTouchDelay={0}
						title={
							<div>
								<Trans i18nKey="sidebar.actions.open">
									Ochish uchun <code className="toggle-btn-sign">]</code> ni
									bosing
								</Trans>
							</div>
						}
						arrow={true}
						placement="right"
					>
						<IconButton variant="toggle" onClick={() => setSideBarToggle(true)}>
							<i className="bi bi-caret-right" />
						</IconButton>
					</BaseTooltip>
				)}
			</div>
		</div>
	)
}

export default SidebarPanel
