import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import STAGE_TYPE_LIST from "shared/stageTypeList"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import * as yup from "yup"
import BaseTable from "components/ui/tables/BaseTable"
import { useState } from "react"

const validationSchema = yup.object({
	
})

const ProductionFromOneStageToAnotherModal = (props) => {
	const { open, setOpen, itemData, setItemData} = props
	const { t } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const { submit, isSubmitting } = useFormSubmit()
	const [staffsList, setStaffsList] = useState([])

	const formik = useFormik({
		initialValues: {
			name: "",
			size: "",
			number: "",
			stages:
				itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
					? itemData?.product?.prepare &&
					  itemData?.product?.prepare.length > 0 &&
					  itemData?.product?.prepare.map((prepareItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: prepareItem?.id,
							sum: prepareItem?.sum
					  }))
					: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
					? itemData?.product?.sewingstage &&
					  itemData?.product?.sewingstage.length > 0 &&
					  itemData?.product?.sewingstage.map((sewingItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: sewingItem?.id,
							sum: sewingItem?.sum
					  }))
					: itemData.stageType === STAGE_TYPE_LIST.IRON.code
					? itemData?.product?.ironstages &&
					  itemData?.product?.ironstages.length > 0 &&
					  itemData?.product?.ironstages.map((ironItem) => ({
							staff_id: "",
							staffNumber: "",
							stage_id: ironItem?.id,
							sum: ironItem?.sum
					  }))
					: []
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = JSON.parse(JSON.stringify(values))
			newValues.stages.forEach((el) => delete el.staffNumber)
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				itemData.stageType === STAGE_TYPE_LIST.PREPARE.code
					? `/staff/unit/prepare/${itemData.id}`
					: itemData.stageType === STAGE_TYPE_LIST.SEAM.code
					? `/staff/unit/sewing/${itemData.id}`
					: itemData.stageType === STAGE_TYPE_LIST.IRON.code
					? `/staff/unit/iron/${itemData.id}`
					: "",
				newValues.name,
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		setItemData("")
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} sm={12} xs={12}>
							<Box className="base-table flex flex-col mt-3">
								<TableContainer className="flex-auto h-full">
								<BaseTable
									emitRefetch={{ setRefetch }}
									dataPath={`admin/sale/show/${itemData.id}`}
									emitTableColumns={{ open, setOpen }}
									tableName="fabrics"
									headCells={[
										{ code: "id", label: "#" },
										{ code: "name", label: t("common.table.product") },
										{ code: "number", label: t("common.table.productsold") },
										{ code: "price", label: t("common.table.productprice2") }
									]}
									columns={[
										{ code: "id" },
										{ code: "name" },
										{ code: "number"},
										{
											code: "price",
											type: "numberFormat",
											decimalScale: 1,
											thousandSeparator: "",
											suffix: " $"
										},
									]}
						/>
								</TableContainer>
							</Box>
						
						</Grid>

						
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ProductionFromOneStageToAnotherModal
