const colors = {
	blackColor: "#000000",
	whiteColor: "#ffffff",
	baseColor: "#182848",
	baseColorLight: "#4b6cb7",
	baseColorLightHover: "#18284826",
	baseColorDisabled: "#6d8eda",
	baseColorHover: "#326ce0",
	baseColorActive: "#0c214b",
	baseColorOutline: "#163d8b6c",
	baseColorDeprecatedBg: "#49649a",
	base1: "#99aed9",
	base2: "#49649a",
	base3: "#2052b8;",
	base4: "#1040a0",
	baseColorActiveDeprecatedF30: "#9bb2e04e",
	baseColorActiveDeprecatedD02: "#9fb8eb",
	warningColor: "#f8a62a",
	successColor: "#57c820",
	infoColor: "#0288d1",
	errorColor: "#f40606",
	shadowColor: "#49649a85",
	formColor: "#007bb2",
	footerColor: "#333333",
	grayColor: "#9ca3af"
}

const colorSeparators = {
	blackColor: "0, 0, 0",
	whiteColor: "255, 255, 255",
	baseColor: "24, 40, 72",
	baseColorLight: "75, 108, 183",
	baseColorLightHover: "24, 40, 72, 0.15",
	baseColorDisabled: "109, 142, 218",
	baseColorHover: "50, 108, 224",
	baseColorActive: "12, 33, 75",
	baseColorOutline: "22, 61, 139, 0.42",
	baseColorDeprecatedBg: "73, 100, 154",
	base1: "153, 174, 217",
	base2: "73, 100, 154",
	base3: "32, 82, 184",
	base4: "16, 64, 160",
	baseColorActiveDeprecatedF30: "155, 178, 224, 0.31",
	baseColorActiveDeprecatedD02: "159, 184, 235",
	warningColor: "248, 166, 42",
	successColor: "87, 200, 32",
	infoColor: "2, 136, 209",
	errorColor: "245, 5, 5",
	formColor: "0, 123, 178",
	footerColor: "51, 51, 51"
}

module.exports = { colors, colorSeparators }
