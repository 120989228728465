import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import STAGE_TYPE_LIST from "shared/stageTypeList"
import { NumericFormat } from "react-number-format"
import * as yup from "yup"

const validationSchema = yup.object({
	/* accessories: yup
		.array()
		.of(
			yup.object({
				id: yup
					.()
					.required(
						"production.fromOneStageToAnotherModal.validation.stageStaffId"
					),
				number: yup
					.number()
					.required("production.fromOneStageToAnotherModal.validation.stageId")
			})
		)
		.min(0, {
			label: "production.fromOneStageToAnotherModal.validation.stagesMin",
			value: 0
		})
		.required("production.fromOneStageToAnotherModal.validation.stages") */
})

const AddAccessories = (props) => {
	const { open, setOpen, itemData, setItemData, setRefetch } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			accessories: itemData?.product?.accessories.map((item) => ({
				id: item?.id,
				number: item?.number,
				price: ""
			}))
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = JSON.parse(JSON.stringify(values))
			submit(
				{ type: "post", contentType: "simple" },
				newValues,
				`/staff/unit/finish/${itemData.id}`,
				newValues.name,
				null,
				true,
				handleFinishRequest
			)
		}
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		setItemData("")
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>{t(`production.fromOneStageToAnotherModal.prepareTitle`)}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} sm={12} xs={12}>
							<Box className="base-table flex flex-col mt-3">
								<TableContainer className="flex-auto h-full">
									<Table
										stickyHeader
										sx={{ minWidth: 500, height: "max-content" }}
									>
										<TableHead>
											<TableRow>
												<TableCell>№</TableCell>
												<TableCell>{t("common.table.stageName")}</TableCell>
												<TableCell>{t("common.table.stageSum")}</TableCell>
												<TableCell>{t("common.table.tabelNumber")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className="overflow-hidden">
											{itemData?.product?.accessories &&
												itemData?.product?.accessories.length > 0 &&
												itemData?.product?.accessories.map((item, index) => (
													<TableRow key={`stage-row-${index}`}>
														<TableCell>{index + 1}</TableCell>
														<TableCell>{item?.name}</TableCell>
														<TableCell>{item?.number}</TableCell>
														<TableCell>
															<NumericFormat
																name={`stage-sum-${index}`}
																label={t("common.fields.stageSum")}
																value={formik.values.accessories[index].price}
																onChange={(event) => {
																	formik.setFieldValue(
																		`accessories.${index}.price`,
																		event.target.value,
																		true
																	)
																}}
																disabled={false}
															/>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default AddAccessories
