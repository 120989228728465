import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormSelectField from "components/ui/form/FormSelectField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useFormSubmit from "hooks/useFormSubmit"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import * as yup from "yup"

const validationSchema = yup.object({
	stage_id: yup
		.number()
		.required("process.subProcessModal.validation.processId"),
	sum: yup
		.number()
		.min(0, { label: "process.subProcessModal.validation.sumMin", value: 0 })
		.required("process.subProcessModal.validation.sum"),
	name: yup
		.string()
		.min(4, { label: "process.subProcessModal.validation.nameMin", value: 4 })
		.required("process.subProcessModal.validation.name")
})

const editValidationSchema = yup.object({
	name: yup
		.string()
		.min(4, { label: "process.subProcessModal.validation.nameMin", value: 4 }),
	sum: yup.string()
})

const SubProcessAddEditModal = (props) => {
	const { open, setOpen, setRefetch, itemId, setItemId, processId } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			stage_id: "",
			sum: "",
			name: ""
		},
		validationSchema: itemId ? editValidationSchema : validationSchema,
		onSubmit: async (values) => {
			if (itemId) {
				submit(
					{ type: "postUpdate", contentType: "simple" },
					values,
					"/admin/stage",
					values.name,
					itemId,
					false,
					handleFinishRequest
				)
			} else
				submit(
					{ type: "post", contentType: "simple" },
					values,
					"/admin/stage",
					values.name,
					null,
					false,
					handleFinishRequest
				)
		}
	})

	const { isLoading, isFetching } = useQuery({
		queryKey: "subProcessSingle",
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/admin/stage/substages/${processId}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			let currentSubProcess = data.find((item) => item.id === itemId)
			formik.setValues({
				stage_id: currentSubProcess?.stage_id,
				sum: currentSubProcess?.sum,
				name: currentSubProcess?.name
			})
		},
		enabled: !hasError && !!itemId,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	const handleFinishRequest = () => {
		setRefetch(true)
		handleClose()
	}

	const handleClose = () => {
		setItemId("")
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				{itemId && formik.values.name ? (
					<span>{t("process.subProcessModal.editTitle")}</span>
				) : (
					<span>{t("process.subProcessModal.addTitle")}</span>
				)}
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				{isLoading || isFetching ? (
					<div className="circular-progress-box py-5">
						<CircularProgress size={35} />
					</div>
				) : (
					<form onSubmit={formik.handleSubmit} className="min-w-[400px]">
						<Grid
							container
							spacing={{ xs: 2, sm: 3, lg: 3 }}
							rowSpacing={1}
							columns={{ xs: 12, sm: 12, lg: 12 }}
						>
							<Grid item={true} sm={6} xs={12}>
								<FormSelectField
									label={t("common.fields.processId")}
									fieldName="stage_id"
									formik={formik}
									path="admin/stage/index"
									readOnly={!!itemId}
									disabled={!!itemId}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormTextField
									label={t("common.fields.stageName")}
									fieldName="name"
									formik={formik}
								/>
							</Grid>

							<Grid item={true} sm={6} xs={12}>
								<FormCurrencyField
									label={t("common.fields.stageSum")}
									fieldName="sum"
									formik={formik}
									decimalScale={0}
								/>
							</Grid>

							<Grid item={true} sm={12} xs={12}>
								<FormActionButtons
									isSubmitting={isSubmitting}
									formType="dialog"
									setOpen={setOpen}
									reset={formik.resetForm}
								/>
							</Grid>
						</Grid>
					</form>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default SubProcessAddEditModal
